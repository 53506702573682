import React from 'react';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import InfoTooltip from 'components/common-components/InfoTooltip';
import NumberRangeField from 'components/form-components/NumberRangeField';
import mapRadiusIcon from '../../assets/images/icons/map-radius.svg';

const Geofencing = ({ singleProperty, properties, handleChange, geofenceEnabledObject }) => {
  return (
    <div className={`${properties['geofence'] ? 'border border-1 border-dark rounded p-1' : ''}`}>
      <div className='d-flex gap-1'>
        <div className={`custom-input-box w-auto`}>
          <div className={`custom-check-option shrink-check-option`}>
            {' '}
            <ToggleSwitch
              name={'geofence'}
              value={properties['geofence']}
              handleChange={(name, value) => {
                handleChange(name, value);
              }}
              disabled={
                !geofenceEnabledObject.geofenceEnable ||
                geofenceEnabledObject.anyLocationElementGeofenceFound
              }
            />
            <label htmlFor={singleProperty['key']} className={`mb-0 cursor-pointer shrink-label`}>
              <span>{t(singleProperty['text'])}</span>
            </label>
          </div>
        </div>
        <InfoTooltip
          text={
            !geofenceEnabledObject.anyLocationElementGeofenceFound
              ? 'info_tooltip_enable_geofence'
              : 'info_tooltip_already_enable_geofence'
          }
          classes=''
        />
      </div>

      {properties['geofence'] ? (
        <div className='mt-3 d-flex flex-column gap-2'>
          <div className='d-flex gap-4'>
            <NumberRangeField
              minRange={5}
              maxRange={100}
              name='geofenceRange'
              value={properties['geofenceRange']}
              handleChange={(name, value) => {
                handleChange(name, value);
              }}
            />

            <NumberFieldWithTag
              name='geofenceRange'
              value={properties['geofenceRange']}
              handleChange={(name, value) => {
                if (value < 5 || value > 100) return;
                handleChange(name, value);
              }}
              min={5}
              max={100}
              tagText={t('form_field_tag_geofence_range')}
            />
          </div>
          {
            <div className='d-flex justify-content-center mb-2'>
              <img src={mapRadiusIcon} width={60} height={60} />
            </div>
          }
          <div className='d-flex gap-1'>
            <SingleCheckBoxField
              name={'geofenceRestricted'}
              value={properties['geofenceRestricted']}
              text={<span>{t('form_field_restricted_to_geofence')}</span>}
              classes='w-auto'
              handleChange={(name, value) => {
                handleChange(name, value);
              }}
              shrink
            />
            <InfoTooltip text={'info_tooltip_geofence_restricted'} classes='' />
          </div>

          <div className='d-flex gap-1'>
            <SingleCheckBoxField
              name={'geofenceEnableDirection'}
              value={properties['geofenceEnableDirection']}
              text={<span>{t('form_field_enable_go_to_direction')}</span>}
              classes='w-auto'
              handleChange={(name, value) => {
                handleChange(name, value);
              }}
              shrink
            />
            <InfoTooltip text={'info_tooltip_geofence_enable_direction'} classes='' />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
Geofencing.propTypes = {
  singleProperty: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  geofenceEnabledObject: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default Geofencing;

import { useTranslation } from 'react-i18next';

const NumberFieldWithTag = ({
  name,
  tagText,
  placeholder,
  value,
  shrink,
  handleChange,
  min,
  max,
  classes,
}) => {
  const { t } = useTranslation();
  const shrinkField = shrink ? 'shrink-field' : '';
  const shrinkTag = shrink ? 'shrink-tag' : '';

  return (
    <div className={`custom-geofence-tag-input-box ${classes ? ' ' + classes : ''}`}>
      <div className='custom-tag-input-field'>
        <input
          type='number'
          id={name}
          name={name}
          className={shrinkField}
          placeholder={t(placeholder)}
          min={min}
          max={max}
          value={value}
          autoComplete='off'
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <div className='input-tag-box'>
          <label className={shrinkTag}>{tagText}</label>
        </div>
      </div>
    </div>
  );
};

NumberFieldWithTag.propTypes = {
  name: PropTypes.string.isRequired,
  tagText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number.isRequired,
  shrink: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};
