import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import layersIcon from 'assets/images/icons/layers.svg';
import dotPointsIcon from 'assets/images/icons/dot-points.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import DropZone from './MidSectionComponents/DropZone';
import FormInstructions from './FormInstructionsComponents/FormInstructions';
import PageElement from './MidSectionComponents/PageElement';
import SectionComponent from './MidSectionComponents/SectionComponent';
import SectionWorkFlowContainer from './MidSectionComponents/SectionWorkFlowContainer';
import SupervisorFieldComponent from './MidSectionComponents/SupervisorFieldComponent';
import SupervisorSectionComponent from './MidSectionComponents/SupervisorSectionComponent';
import UserFieldComponent from './MidSectionComponents/UserFieldComponent';
import { fieldCategoryList, formElementsDefaultProperties } from './utils';
import { updateSavedChanges } from 'config/config';
import {
  moveElementToPosition,
  removeFieldWithChildElements,
  removeMappingFromPageMapObject,
  removePageElementWithChildElements,
  removeSectionWithChildElements,
  removeSingleTriggerType,
  swapFieldElements,
  swapPageElements,
  swapSectionElements,
} from './actions';

function FormPreview({
  selectedFieldCategory,
  setSelectedFieldCategory,
  selectedField,
  setSelectedField,
  formProperties,
  setFormProperties,
  draggedElement,
  setDraggedElement,
  direction,
  dataSourcesList,
  propertiesSectionRef,
  formInstructions,
  formId,
  formFirstVersionId,
  getFormDetail,
}) {
  const { t } = useTranslation();
  const [propertiesSectionHeight, setPropertiesSectionHeight] = useState(null);
  const [statusFieldAlreadyAdded, setStatusFieldAlreadyAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Object.keys(formProperties).length - 2);
  const [selectedView, setSelectedView] = useState('list-view');
  const [fieldDragged, setFieldDragged] = useState(null);
  useEffect(() => {
    setTotalPages(Object.keys(formProperties).length - 2);
  }, [formProperties]);

  const clearDrag = () => {
    setDraggedElement(null);
    setFieldDragged(null);
  };

  const onComponentDrop = (index, isDropInsideSection = false, checkSectionEndIndex = false) => {
    if (draggedElement && draggedElement.elementType) {
      let properties = cloneDeep(formProperties);
      if (draggedElement.elementType === 'supervisor_status') {
        if (properties.otherDetails.hasStatusFieldInForm) {
          setStatusFieldAlreadyAdded(true);
          return;
        } else {
          properties.otherDetails.hasStatusFieldInForm = true;
        }
      }
      updateSavedChanges(true, 'FORM_DESIGN');

      const currentTimeStamp = Date.now();
      if (draggedElement.elementType !== 'page') {
        let elementToAdd = {
          elementType: draggedElement.elementType,
          properties: cloneDeep(formElementsDefaultProperties[draggedElement.elementType]),
          isOpen: false,
        };
        // console.log(elementToAdd.properties);

        if (
          draggedElement.elementType !== 'section' &&
          draggedElement.elementType !== 'supervisor_section'
        )
          elementToAdd = {
            ...elementToAdd,
            uniqueIdToReplace: `field_${currentTimeStamp}`,
          };

        if (draggedElement.elementType === 'supervisor_response') {
          const newId = uuidV4();
          // For replacement of these by element global id
          // elementToAdd.actionReferenceId = newId;
          elementToAdd.workFlowSourceElementReferenceId = newId;
        }
        if (draggedElement.elementType === 'supervisor_section') {
          const newId = uuidV4();
          // For replacement of these by element global id
          // elementToAdd.sectionReferenceId = newId;
          elementToAdd.triggerFlowReferenceId = newId;
        }
        if (elementToAdd.properties.otherProperties)
          elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;
        let elementAddedIn =
          draggedElement.fieldCategory === 'user-field' ? 'page' + currentPage : 'supervisorFields';

        // If dropped outside section so need to place after section elements
        if (checkSectionEndIndex)
          index = getSectionEndIndex(properties[elementAddedIn].elements, index);

        if (index === -2) {
          // if (draggedElement.elementType !== 'section' && isDropInsideSection)
          elementToAdd.isInSection =
            draggedElement.elementType !== 'section' && isDropInsideSection;
          properties[elementAddedIn].elements.push(elementToAdd);
        } else {
          let elementsList = properties[elementAddedIn].elements;

          // skip until there is child element
          let surpassIndex = index;
          for (; surpassIndex < elementsList.length; surpassIndex++) {
            if (!elementsList[surpassIndex + 1] || !elementsList[surpassIndex + 1].childReferenceId)
              break;
          }
          // if (draggedElement.elementType !== 'section' && isDropInsideSection)
          elementToAdd.isInSection =
            draggedElement.elementType !== 'section' && isDropInsideSection;
          elementsList.splice(surpassIndex + 1, 0, elementToAdd);
        }

        if (
          draggedElement.fieldCategory === 'user-field' &&
          !['page', 'section', 'image', 'dataSource', 'externalApi'].includes(
            draggedElement.elementType,
          )
        ) {
          properties.otherDetails.allFormElementsObj = {
            ...properties.otherDetails.allFormElementsObj,
            [elementToAdd.uniqueIdToReplace]: {
              fieldName: 'UnTitled',
              fieldId: elementToAdd.uniqueIdToReplace,
            },
          };
        }

        if (
          draggedElement.fieldCategory === 'user-field' &&
          !['page', 'section', 'image'].includes(draggedElement.elementType)
        ) {
          properties.otherDetails.onChangeTriggerElementsObj = {
            ...properties.otherDetails.onChangeTriggerElementsObj,
            [elementToAdd.uniqueIdToReplace]: {
              fieldName: 'UnTitled',
              fieldId: elementToAdd.uniqueIdToReplace,
            },
          };
        }
      } else {
        const newId = uuidV4();
        let pageNumber = currentPage + 1;
        let elementToAdd = {
          elementType: 'page',
          properties: cloneDeep(formElementsDefaultProperties.page),
          isOpen: false,
          // For replacement of these by element global id
          triggerFlowReferenceId: newId,
        };
        elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

        for (let index = totalPages; index > currentPage; index--) {
          let nextPage = index + 1;
          properties['page' + nextPage] = { elements: properties['page' + index].elements };
        }

        properties['page' + pageNumber] = {
          elements: [elementToAdd],
        };

        const orderedFormProps = Object.keys(cloneDeep(properties))
          .sort()
          .reduce((obj, key) => {
            obj[key] = properties[key];
            return obj;
          }, {});
        properties = cloneDeep(orderedFormProps);
      }
      setFormProperties(properties);
      if (draggedElement.elementType === 'page') {
        setCurrentPage((prev) => prev + 1);
        setSelectedField(null);
      } else {
        if (
          selectedField &&
          selectedField.position > index &&
          index !== -2 &&
          selectedField.type === draggedElement.fieldCategory
        ) {
          setSelectedField((prev) => {
            return { ...prev, position: prev.position + 1 };
          });
        }
      }
      setDraggedElement(null);
    }
  };

  const onMoveElement = (index, isMovedInsideSection = false, checkSectionEndIndex = false) => {
    if (fieldDragged) {
      updateSavedChanges(true, 'FORM_DESIGN');
      setSelectedField(null);
      let properties = cloneDeep(formProperties);
      let elementAddedIn =
        fieldDragged.fieldCategory === 'user-field' ? 'page' + currentPage : 'supervisorFields';
      let currentPageElements = properties[elementAddedIn].elements;

      // If dropped outside section so need to place after section elements
      if (checkSectionEndIndex)
        index = getSectionEndIndex(properties[elementAddedIn].elements, index);

      console.log('move ', index);
      moveElementToPosition(
        currentPageElements,
        fieldDragged.position,
        index,
        isMovedInsideSection,
      );

      properties[elementAddedIn].elements = currentPageElements;
      setFormProperties(properties);
    }
    setFieldDragged(null);
  };

  const addPage = () => {
    let properties = cloneDeep(formProperties);
    const newId = uuidV4();

    updateSavedChanges(true, 'FORM_DESIGN');

    const currentTimeStamp = Date.now();

    let pageNumber = totalPages + 1;
    let elementToAdd = {
      elementType: 'page',
      properties: cloneDeep(formElementsDefaultProperties.page),
      isOpen: false,
      // For replacement of these by element global id
      triggerFlowReferenceId: newId,
    };
    elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

    properties['page' + pageNumber] = { elements: [elementToAdd] };

    const orderedFormProps = Object.keys(cloneDeep(properties))
      .sort()
      .reduce((obj, key) => {
        obj[key] = properties[key];
        return obj;
      }, {});
    properties = cloneDeep(orderedFormProps);

    setFormProperties(properties);
    setCurrentPage(pageNumber);
    setSelectedField(
      cloneDeep({
        type: 'user-field',
        position: 0,
        currentPage: pageNumber,
        elementType: elementToAdd.elementType,
      }),
    );
  };

  const addWorkflowInSection = (sectionElementIndex) => {
    console.log(sectionElementIndex);

    const defaultWorkflow = {
      type: '',
      operator: 'is',
      sourceElementGlobalId: null, //If action element is already saved in backend
      workFlowSourceElementReferenceId: null, //If action element is not saved in backend
      // sourceElementReferenceId: null, // Only for frontend mapping // Not used now
      sourceValue: '',
      triggerFlowReferenceId: null, //If section element is not saved in backend
      actionId: null, //If section element is already saved in backend
      title: '',
    };
    // add default structure of workflow
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    let sectionToAddWorkflow = null;
    // let foundSectionIndex = -1;
    // for (let index = elementIndex; index >= 0; index--) {
    //   if (properties.supervisorFields.elements[index].elementType === 'supervisor_section') {
    //     sectionToAddWorkflow = properties.supervisorFields.elements[index];
    //     foundSectionIndex = index;
    //     break;
    //   }
    // }

    sectionToAddWorkflow = properties.supervisorFields.elements[sectionElementIndex];

    if (sectionToAddWorkflow) {
      if (!sectionToAddWorkflow.sectionWorkFlows)
        sectionToAddWorkflow.sectionWorkFlows = [{ ...defaultWorkflow }];
      else sectionToAddWorkflow.sectionWorkFlows.push({ ...defaultWorkflow });
    }

    console.log(properties.supervisorFields.elements[sectionElementIndex]);
    setFormProperties(properties);
  };

  const handleSectionWorkflowChange = (
    sectionElementIndex,
    workflowIndex,
    name,
    value,
    referenceData = {},
  ) => {
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    if (name)
      properties.supervisorFields.elements[sectionElementIndex].sectionWorkFlows[workflowIndex][
        name
      ] = value;
    for (const [k, v] of Object.entries(referenceData)) {
      properties.supervisorFields.elements[sectionElementIndex].sectionWorkFlows[workflowIndex][k] =
        v;
    }

    console.log(properties.supervisorFields.elements[sectionElementIndex]);
    setFormProperties(properties);
  };

  const addTriggerInElement = (choiceType, elementIndex) => {
    const defaultTrigger = { matcher: 'is', matchingValue: '', types: [] };
    // add default structure of trigger
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    let elementToAddTrigger = null;
    if (choiceType === 'checkList')
      elementToAddTrigger = properties['page' + currentPage].elements[elementIndex];
    else if (choiceType === 'supervisor_response')
      elementToAddTrigger = properties['supervisorFields'].elements[elementIndex];

    if (elementToAddTrigger) {
      if (!elementToAddTrigger.triggers) elementToAddTrigger.triggers = [{ ...defaultTrigger }];
      else elementToAddTrigger.triggers.push({ ...defaultTrigger });
    }

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const handleTriggerElementChange = (choiceType, elementIndex, triggerIndex, name, value) => {
    // for matcher and matchingValue
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    if (choiceType === 'checkList')
      properties['page' + currentPage].elements[elementIndex].triggers[triggerIndex][name] = value;
    else if (choiceType === 'supervisor_response')
      properties['supervisorFields'].elements[elementIndex].triggers[triggerIndex][name] = value;

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const findInNextDepth = (elements, elementIndex) => {
    let newSurpassIndex = elementIndex;
    let lastDepthLastChildTriggerElement = null;
    const lastDepthTriggers = elements[elementIndex].triggers;
    if (lastDepthTriggers?.length) {
      for (let index = lastDepthTriggers.length - 1; index >= 0; index--) {
        lastDepthLastChildTriggerElement = elements[elementIndex].triggers[index].types
          .filter((t) => t.type === 'ask_a_question')
          .at(-1);
        if (lastDepthLastChildTriggerElement) break;
      }
    }
    if (lastDepthLastChildTriggerElement) {
      newSurpassIndex = elements.findIndex(
        (e) => e.childReferenceId === lastDepthLastChildTriggerElement.childReferenceId,
      );
    }
    return newSurpassIndex;
  };

  const findSurpassIndex = (elements, elementIndex, triggerIndex) => {
    let surpassIndex = 0;
    const lastChildElement = elements[elementIndex].triggers[triggerIndex].types
      .filter((t) => t.type === 'ask_a_question')
      .at(-1);

    if (triggerIndex === 0) {
      if (lastChildElement) {
        surpassIndex = elements.findIndex(
          (e) => e.childReferenceId === lastChildElement.childReferenceId,
        );

        if (surpassIndex > -1) {
          //search next level children
          surpassIndex = findInNextDepth(elements, surpassIndex);
        }
      }
    } else {
      console.log({ lastChildElement });

      if (lastChildElement) {
        surpassIndex = elements.findIndex(
          (e) => e.childReferenceId === lastChildElement.childReferenceId,
        );
      } else {
        let previousTriggerLastChild = null;
        for (let index = triggerIndex - 1; index >= 0; index--) {
          previousTriggerLastChild = elements[elementIndex].triggers[index].types
            .filter((t) => t.type === 'ask_a_question')
            .at(-1);
          if (previousTriggerLastChild) break;
        }
        if (previousTriggerLastChild) {
          surpassIndex = elements.findIndex(
            (e) => e.childReferenceId === previousTriggerLastChild.childReferenceId,
          );

          if (surpassIndex > -1) {
            //search next level children
            surpassIndex = findInNextDepth(elements, surpassIndex);
          }
        }
      }
    }

    return surpassIndex;
  };

  const addTriggerType = (
    choiceType,
    elementIndex,
    triggerIndex,
    triggerType,
    elementTypeOrPage, //for ask_a_question this will be elementType and for go_to this will be page|{id}
    referenceData = {},
  ) => {
    console.log(elementIndex, triggerIndex, triggerType, elementTypeOrPage);
    let properties = cloneDeep(formProperties);
    const elements =
      {
        checkList: properties['page' + currentPage].elements,
        supervisor_response: properties['supervisorFields'].elements,
      }[choiceType] || null;

    updateSavedChanges(true, 'FORM_DESIGN');
    setSelectedField(null);

    let elementToAdd = undefined;

    if (triggerType === 'ask_a_question') {
      const newId = uuidV4();
      const currentTimeStamp = Date.now();
      elementToAdd = {
        elementType: elementTypeOrPage,
        properties: cloneDeep(formElementsDefaultProperties[elementTypeOrPage]),
        isOpen: false,
        uniqueIdToReplace: `field_${currentTimeStamp}`,
        triggerFlowReferenceId: newId,
        childReferenceId: newId,
        isInSection: elements[elementIndex].isInSection || false,
      };

      if (elementTypeOrPage === 'supervisor_response')
        elementToAdd.workFlowSourceElementReferenceId = newId;

      if (elementToAdd.properties.otherProperties)
        elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;
      let surpassIndex = findSurpassIndex(elements, elementIndex, triggerIndex);

      //if surpass index > 0 then their are child elements needs to skip
      if (surpassIndex > 0) elements.splice(surpassIndex + 1, 0, elementToAdd);
      else elements.splice(elementIndex + 1, 0, elementToAdd);
    }

    const triggerToPush =
      triggerType === 'ask_a_question'
        ? {
            type: triggerType,
            triggerFlowReferenceId: elementToAdd.triggerFlowReferenceId, // for backend mapping
            childReferenceId: elementToAdd.childReferenceId, // for frontend mapping
            elementGlobalId: null,
          }
        : triggerType === 'go_to'
        ? {
            type: elementTypeOrPage,
            triggerFlowReferenceId: referenceData.triggerFlowReferenceId ?? null,
            elementGlobalId: referenceData.elementGlobalId ?? null,
          }
        : { type: triggerType };
    elements[elementIndex].triggers[triggerIndex].types.push(cloneDeep(triggerToPush));

    // add to gotoPageTriggerMapObj when go_to page is added
    if (triggerType === 'go_to') {
      const currentPageElement = elements.find((e) => e.elementType === 'page');
      const existingArr =
        properties.otherDetails.gotoPageTriggerMapObj[
          currentPageElement.elementGlobalId ?? currentPageElement.triggerFlowReferenceId
        ] ?? [];

      const updatedMapObj = {
        ...properties.otherDetails.gotoPageTriggerMapObj,
        [currentPageElement.elementGlobalId ?? currentPageElement.triggerFlowReferenceId]: [
          ...existingArr,
          referenceData.elementGlobalId ?? referenceData.triggerFlowReferenceId,
        ],
      };

      properties.otherDetails.gotoPageTriggerMapObj = cloneDeep(updatedMapObj);
    }
    setFormProperties(properties);
  };

  const removeTriggerType = (choiceType, elementIndex, triggerIndex, typeIndex) => {
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');
    setSelectedField(null);

    const elements =
      {
        checkList: properties['page' + currentPage].elements,
        supervisor_response: properties['supervisorFields'].elements,
      }[choiceType] || null;
    // remove managerResponse type trigger from workflow too
    if (choiceType === 'supervisor_response') {
      const triggerType = elements[elementIndex].triggers[triggerIndex].types[typeIndex];
      if (triggerType?.type === 'ask_a_question') {
        const triggerToBeRemovedId = triggerType.childReferenceId;
        if (triggerToBeRemovedId) {
          removeActionsFromWorkflow(elements, [triggerToBeRemovedId], 'supervisor_response');
        }
      }
    }

    const triggerType = elements[elementIndex].triggers[triggerIndex].types[typeIndex];
    if (choiceType === 'checkList' && triggerType.type === 'user_page') {
      const currentPageElement = properties['page' + currentPage].elements.find(
        (e) => e.elementType === 'page',
      );

      removeMappingFromPageMapObject(
        properties.otherDetails.gotoPageTriggerMapObj,
        currentPageElement.elementGlobalId ?? currentPageElement.triggerFlowReferenceId,
        [triggerType.elementGlobalId ?? triggerType.triggerFlowReferenceId],
      );
    }

    removeSingleTriggerType(elements, elementIndex, triggerIndex, typeIndex);

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  // const removeAllTriggers = (elementIndex) => {
  //   let properties = cloneDeep(formProperties);
  //   updateSavedChanges(true, 'FORM_DESIGN');
  //   setSelectedField(null);

  //   const elementTriggers = properties['page' + currentPage].elements[elementIndex].triggers;

  //   if (elementTriggers?.length) {
  //     for (let triggerIndex = elementTriggers.length - 1; triggerIndex >= 0; triggerIndex--) {
  //       const triggerTypes = elementTriggers[triggerIndex].types;
  //       for (let typeIndex = triggerTypes.length - 1; typeIndex >= 0; typeIndex--) {
  //         removeSingleTriggerType(
  //           properties['page' + currentPage].elements,
  //           elementIndex,
  //           triggerIndex,
  //           typeIndex,
  //         );
  //       }
  //     }
  //     if (elementTriggers.every((t) => t.types.length === 0))
  //       properties['page' + currentPage].elements[elementIndex].triggers = null;
  //   }

  //   console.log(properties['page' + currentPage].elements[elementIndex]);
  //   setFormProperties(properties);
  // };

  const toggleUserElement = (index) => {
    let properties = cloneDeep(formProperties);

    const isOpen = selectedField?.position === index ? false : true;
    properties['page' + currentPage].elements[index].isOpen = isOpen;
    setFormProperties(properties);
  };

  const toggleSupervisorElement = (index) => {
    let properties = cloneDeep(formProperties);

    const isOpen = selectedField?.position === index ? false : true;
    properties['supervisorFields'].elements[index].isOpen = isOpen;
    setFormProperties(properties);
  };

  const toggleAllElements = (isOpen) => {
    let properties = cloneDeep(formProperties);

    Array.from(Array(totalPages).keys()).map((_, i) => {
      for (let index = 0; index < properties['page' + (i + 1)].elements.length; index++) {
        properties['page' + (i + 1)].elements[index].isOpen = isOpen;
      }
    });
    for (let index = 0; index < properties['supervisorFields'].elements.length; index++) {
      properties['supervisorFields'].elements[index].isOpen = isOpen;
    }
    setFormProperties(properties);
  };

  useEffect(() => {
    if (!propertiesSectionRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      // Do what you want to do when the size of the element changes
      if (entries.length) {
        const contentReact = entries[0].contentRect;
        setPropertiesSectionHeight(contentReact.height);
      }
    });
    resizeObserver.observe(propertiesSectionRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const updateUniqueIdValues = (elementsList) => {
    const currentTimeStamp = Date.now();
    elementsList.map((singleElement, index) => {
      const fieldUniqueId = `field_${currentTimeStamp + index}`;
      if (
        singleElement.properties.otherProperties &&
        singleElement.properties.otherProperties.fieldUniqueId
      )
        singleElement.properties.otherProperties.fieldUniqueId = fieldUniqueId;
      // singleElement.properties.otherProperties.fieldUniqueId =
      //   'duplicate_' +
      //   (currentPage + 1) +
      //   '_' +
      //   singleElement.properties.otherProperties.fieldUniqueId;
      if (
        !(
          singleElement.elementType === 'page' ||
          singleElement.elementType === 'section' ||
          singleElement.elementType === 'supervisor_section'
        )
      )
        singleElement.uniqueIdToReplace = fieldUniqueId;
      if (singleElement.elementType === 'dataSource')
        singleElement.properties.basicProperties.bindingList = [];
      if (singleElement.elementType === 'supervisor_response') {
        const newId = uuidV4();
        singleElement.workFlowSourceElementReferenceId = newId;
        singleElement.sourceElementGlobalId = newId;
      }
      if (singleElement.elementType === 'supervisor_section')
        singleElement.triggerFlowReferenceId = uuidV4();
      if (singleElement.elementType === 'page') singleElement.triggerFlowReferenceId = uuidV4();

      delete singleElement.elementGlobalId;
      delete singleElement.company;
      delete singleElement._id;
      delete singleElement.triggers; // also remove triggers for new element
      delete singleElement.sectionWorkFlows; // also remove sectionWorkFlows for new element
    });
    return elementsList;
  };
  const unBindRemovedElements = (elementsList, formProps) => {
    let allRemovedElementsGlobalIds = elementsList.map((singleElement) => {
      return singleElement.elementGlobalId
        ? singleElement.elementGlobalId
        : singleElement.uniqueIdToReplace;
    });
    Object.keys(formProps).map((singlePageElement) => {
      if (singlePageElement.indexOf('page') === 0) {
        formProps[singlePageElement].elements.map((singlePageElement) => {
          if (singlePageElement.elementType === 'dataSource') {
            let updatedBindingList =
              singlePageElement.properties.basicProperties.bindingList.filter(
                (singleBindElement) => {
                  if (
                    !(
                      singleBindElement &&
                      allRemovedElementsGlobalIds.indexOf(singleBindElement.fieldId) > -1
                    )
                  ) {
                    return singleBindElement;
                  }
                },
              );
            singlePageElement.properties.basicProperties.bindingList = updatedBindingList;
          }
        });
      }
    });
    return formProps;
  };

  const removeActionsFromWorkflow = (elements, actionList, deletedItemType) => {
    elements.forEach((el) => {
      if (el.elementType === 'supervisor_section') {
        if (deletedItemType === 'supervisor_response') {
          el.sectionWorkFlows = el.sectionWorkFlows?.filter(
            (workflow) =>
              !actionList.includes(
                workflow.sourceElementGlobalId ?? workflow.workFlowSourceElementReferenceId,
              ),
          );
        } else {
          el.sectionWorkFlows = el.sectionWorkFlows?.filter(
            (workflow) =>
              !actionList.includes(workflow.actionId ?? workflow.triggerFlowReferenceId),
          );
        }
      }
    });
  };

  const modifySectionsWorkflow = (currentPageElements, index) => {
    const deletedElemType = currentPageElements[index].elementType;
    if (['supervisor_response', 'supervisor_section'].includes(deletedElemType)) {
      const deletedIds =
        deletedElemType === 'supervisor_section'
          ? [
              currentPageElements[index].elementGlobalId ??
                currentPageElements[index].triggerFlowReferenceId,
            ]
          : [
              currentPageElements[index].elementGlobalId ??
                currentPageElements[index].workFlowSourceElementReferenceId,
            ];
      if (deletedElemType === 'supervisor_response') {
        const nestedManagerResponseFieldIds = [];
        for (let i = index + 1; i < currentPageElements.length; i++) {
          if (!currentPageElements[i].childReferenceId) break;
          if (currentPageElements[i].elementType === 'supervisor_response') {
            nestedManagerResponseFieldIds.push(
              currentPageElements[i].elementGlobalId ||
                currentPageElements[i].workFlowSourceElementReferenceId,
            );
          }
        }
        deletedIds.push(...nestedManagerResponseFieldIds);
      }
      // remove actions from workflow
      removeActionsFromWorkflow(currentPageElements, deletedIds, deletedElemType);
    }
  };

  const changeFormElementOrder = (type, detail, action) => {
    updateSavedChanges(true, 'FORM_DESIGN');
    setSelectedField(null);
    let removedElements = [];
    let deletedFieldType = '';
    let properties = cloneDeep(formProperties);
    let elementAddedIn = ['supervisor_field', 'supervisor_section'].includes(type)
      ? 'supervisorFields'
      : 'page' + currentPage;
    let currentPageElements = properties[elementAddedIn].elements;
    const currentPageElement =
      properties[elementAddedIn].elements.find((e) => e.elementType === 'page') ?? null;
    const currentPageId =
      currentPageElement?.elementGlobalId ?? currentPageElement?.triggerFlowReferenceId ?? null;
    let isNeededToReplaceElements = true;
    const deletedFieldIds = [];

    console.log(type, detail, action);
    /* New Element actions implementation */
    if (type === 'field' || type === 'supervisor_field') {
      if (action === 'increase' || action === 'decrease') {
        swapFieldElements(currentPageElements, detail.position, action, type);
        // if (type === 'field') swapFieldElements(currentPageElements, detail.position, action, type);
        // else swapSupervisorFieldElements(currentPageElements, detail.position, action, type);
      } else if (action === 'delete') {
        // remove deleted 'supervisor_response' from sections workflow
        modifySectionsWorkflow(currentPageElements, detail.position);
        const deletedFieldId = removeFieldWithChildElements(
          currentPageElements,
          detail.position,
          properties.otherDetails.gotoPageTriggerMapObj,
          currentPageId,
        );
        if (deletedFieldId) deletedFieldIds.push(deletedFieldId);
      }
    } else if (type === 'section' || type === 'supervisor_section') {
      if (action === 'increase' || action === 'decrease') {
        swapSectionElements(currentPageElements, detail.position, action, type);
        // if (type === 'section')
        //   swapSectionElements(currentPageElements, detail.position, action, type);
        // else swapSupervisorSectionElements(currentPageElements, detail.position, action, type);
      } else if (action === 'delete') {
        // remove deleted 'supervisor_section' from sections workflow
        modifySectionsWorkflow(currentPageElements, detail.position);
        removeSectionWithChildElements(
          currentPageElements,
          detail.position,
          removedElements,
          properties.otherDetails.gotoPageTriggerMapObj,
          currentPageId,
        );
      }
    } else if (type === 'page') {
      if (action === 'increase' || action === 'decrease') {
        isNeededToReplaceElements = false;
        swapPageElements(properties, detail, action);
      } else if (action === 'delete') {
        isNeededToReplaceElements = false;
        removePageElementWithChildElements(
          properties,
          detail,
          currentPage,
          setCurrentPage,
          setTotalPages,
          removedElements,
        );
      }
    }
    /* ********************* */

    if (action === 'increase' || action === 'decrease') {
      // let replacedIndex = action === 'increase' ? detail.position + 1 : detail.position - 1;
      if (type === 'field' || type === 'section' || type === 'supervisor_field') {
        // [currentPageElements[detail.position], currentPageElements[replacedIndex]] = [
        //   currentPageElements[replacedIndex],
        //   currentPageElements[detail.position],
        // ];
      } else {
        // isNeededToReplaceElements = false;
        // const replacingPageElements = properties['page' + detail.pageNumber].elements;
        // properties['page' + detail.pageNumber].elements =
        //   properties['page' + replacedIndex].elements;
        // properties['page' + replacedIndex].elements = replacingPageElements;
      }
    }
    if (type === 'field' || type === 'supervisor_field') {
      if (action === 'delete') {
        // deletedFieldType = currentPageElements[detail.position].elementType;
        // removedElements.push(currentPageElements[detail.position]);
        // currentPageElements.splice(detail.position, 1);
      } else if (action === 'duplicate') {
        let updatedElements = updateUniqueIdValues([
          cloneDeep(currentPageElements[detail.position]),
        ]);
        currentPageElements.splice(detail.position + 1, 0, updatedElements[0]);
      }
    } else if (action === 'delete') {
      if (type === 'page') {
        // isNeededToReplaceElements = false;
        // removedElements = properties['page' + detail.pageNumber].elements;
        // if (detail.position === detail.totalPages) {
        //   delete properties['page' + detail.pageNumber];
        //   // setCurrentPage(currentPage - 1);
        // } else {
        //   for (let index = detail.position; index < detail.totalPages; index++) {
        //     let nextPage = index + 1;
        //     properties['page' + index].elements = properties['page' + nextPage].elements;
        //   }
        //   delete properties['page' + detail.totalPages];
        // }
        // setTotalPages(totalPages - 1);
      } else if (type === 'section') {
        // let deletionIndex = -1;
        // let deletionCount = 0;
        // for (let index = detail.position; index < currentPageElements.length; index++) {
        //   if (index !== detail.position && currentPageElements[index].elementType === 'section') {
        //     break;
        //   }
        //   if (deletionIndex === -1) deletionIndex = index;
        //   deletionCount++;
        //   removedElements.push(currentPageElements[index]);
        // }
        // if (deletionIndex > -1) {
        //   currentPageElements.splice(deletionIndex, deletionCount);
        // }
      }
    } else if (action === 'duplicate') {
      let elementsListToDuplicate = [];
      let elementsToSkip = 0;
      if (type === 'page') {
        for (let index = 0; index < properties['page' + detail.position].elements.length; index++) {
          const element = properties['page' + detail.position].elements[index];
          if (!element.childReferenceId) elementsListToDuplicate.push(cloneDeep(element));
        }
      } else if (type === 'section') {
        for (let index = detail.position; index < currentPageElements.length; index++) {
          if (index !== detail.position && currentPageElements[index].elementType === 'section') {
            break;
          }
          elementsToSkip++;
          if (!currentPageElements[index].childReferenceId)
            elementsListToDuplicate.push(cloneDeep(currentPageElements[index]));
        }
      } else if (type === 'supervisor_section') {
        for (let index = detail.position; index < currentPageElements.length; index++) {
          if (
            index !== detail.position &&
            currentPageElements[index].elementType === 'supervisor_section'
          ) {
            break;
          }
          elementsToSkip++;
          if (!currentPageElements[index].childReferenceId)
            elementsListToDuplicate.push(cloneDeep(currentPageElements[index]));
        }
      }

      let updatedElements = updateUniqueIdValues(elementsListToDuplicate);
      if (type === 'page') {
        isNeededToReplaceElements = false;
        if (detail.position === detail.totalPages) {
          properties['page' + (detail.totalPages + 1)] = {
            elements: updatedElements,
          };
        } else {
          for (let index = detail.totalPages; index > detail.position; index--) {
            let nextPage = index + 1;
            properties['page' + nextPage] = { elements: properties['page' + index].elements };
          }
          properties['page' + (detail.position + 1)] = { elements: updatedElements };
        }
      } else if (type === 'section' || type === 'supervisor_section') {
        currentPageElements.splice(detail.position + elementsToSkip, 0, ...updatedElements);
      }
    }

    if (deletedFieldIds.length) {
      deletedFieldIds.forEach((deletedFieldId) => {
        if (deletedFieldId) {
          delete properties.otherDetails.allFormElementsObj[deletedFieldId];
          delete properties.otherDetails.onChangeTriggerElementsObj[deletedFieldId];
        }
      });
    }

    // when any changes are done in current page
    if (isNeededToReplaceElements) properties[elementAddedIn].elements = currentPageElements;
    setFormProperties(properties);
    if (removedElements.length > 0) {
      let updatedFormProperties = unBindRemovedElements(removedElements, properties);
      setFormProperties(updatedFormProperties);
    }
    if (deletedFieldType === 'supervisor_status') {
      properties.otherDetails.hasStatusFieldInForm = false;
      setFormProperties(properties);
    }
  };
  const pageUpdated = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedField(null);
  };

  const checkSection = (pageElements, currentIndex, fieldCategory) => {
    let isInSection = false;
    let isSectionOpen = true;
    const sectionElementType = fieldCategory === 'user-field' ? 'section' : 'supervisor_section';
    const lastIndex = fieldCategory === 'user-field' ? 1 : 0;
    if (pageElements[currentIndex].elementType === sectionElementType)
      return { isInSection: false, isSectionOpen: pageElements[currentIndex].isOpen };

    if (!pageElements[currentIndex].isInSection)
      return { isInSection: false, isSectionOpen: false };

    for (let index = currentIndex - 1; index >= lastIndex; index--) {
      if (pageElements[index].elementType === sectionElementType) {
        isInSection = true;
        isSectionOpen = pageElements[index].isOpen;
        break;
      }
    }
    return { isInSection, isSectionOpen };
  };

  // const checkSupervisorSectionEnd = (pageElements, currentIndex) => {
  //   let isSectionEnd = false;
  //   let sectionElementIndex = -1;
  //   let sectionWorkFlows = [];
  //   if (pageElements[currentIndex].elementType === 'supervisor_section')
  //     return { isSectionEnd, sectionWorkFlows, sectionElementIndex };
  //   if (
  //     pageElements.length - 1 === currentIndex ||
  //     pageElements[currentIndex + 1].elementType === 'supervisor_section'
  //   )
  //     isSectionEnd = true;

  //   let isSectionExist = false;
  //   for (let index = currentIndex; index >= 0; index--) {
  //     if (pageElements[index].elementType === 'supervisor_section') {
  //       sectionWorkFlows = pageElements[index].sectionWorkFlows ?? [];
  //       isSectionExist = true;
  //       sectionElementIndex = index;
  //       break;
  //     }
  //   }
  //   // If no section exist above
  //   if (!isSectionExist) isSectionEnd = false;

  //   return { isSectionEnd, sectionWorkFlows, sectionElementIndex };
  // };

  const checkUserSectionEnd = (pageElements, currentIndex) => {
    let isSectionEnd = false;
    let sectionElementIndex = -1;

    if (
      pageElements[currentIndex].childReferenceId ||
      (pageElements[currentIndex].elementType !== 'section' &&
        !pageElements[currentIndex].isInSection)
    )
      return { isSectionEnd, sectionElementIndex };

    if (
      pageElements[currentIndex].elementType === 'section' &&
      (!pageElements[currentIndex + 1] ||
        (pageElements[currentIndex + 1] &&
          (!pageElements[currentIndex + 1].isInSection ||
            pageElements[currentIndex + 1].elementType === 'section')))
    )
      return { isSectionEnd: true, sectionElementIndex: currentIndex };

    if (
      pageElements[currentIndex].elementType !== 'section' &&
      pageElements[currentIndex].isInSection
    ) {
      for (let i = currentIndex + 1; i <= pageElements.length; i++) {
        if (pageElements[i]?.childReferenceId) continue;
        isSectionEnd = pageElements[i]?.isInSection ? false : true;
        break;
      }
      //need to find section index above
      // for (let index = currentIndex - 1; index >= 1; index--) {
      //   if (pageElements[index].elementType === 'section') {
      //     sectionElementIndex = index;
      //     break;
      //   }
      // }

      return { isSectionEnd, sectionElementIndex };
    }
    return { isSectionEnd, sectionElementIndex };
  };

  const checkSectionEnd = (pageElements, fieldCategory, currentIndex) => {
    const sectionType = fieldCategory === 'supervisor-field' ? 'supervisor_section' : 'section';
    const lastIndex = fieldCategory === 'supervisor-field' ? 0 : 1;

    let isSectionEnd = false;
    let sectionElementIndex = -1;

    if (
      pageElements[currentIndex].childReferenceId ||
      (pageElements[currentIndex].elementType !== sectionType &&
        !pageElements[currentIndex].isInSection)
    )
      return { isSectionEnd, sectionElementIndex };

    if (
      pageElements[currentIndex].elementType === sectionType &&
      (!pageElements[currentIndex + 1] ||
        (pageElements[currentIndex + 1] &&
          (!pageElements[currentIndex + 1].isInSection ||
            pageElements[currentIndex + 1].elementType === sectionType)))
    )
      return { isSectionEnd: true, sectionElementIndex: currentIndex };

    if (
      pageElements[currentIndex].elementType !== sectionType &&
      pageElements[currentIndex].isInSection
    ) {
      for (let i = currentIndex + 1; i <= pageElements.length; i++) {
        if (pageElements[i]?.childReferenceId) continue;
        isSectionEnd = pageElements[i]?.isInSection ? false : true;
        break;
      }
      // need to find section index above
      for (let index = currentIndex - 1; index >= lastIndex; index--) {
        if (pageElements[index].elementType === sectionType) {
          sectionElementIndex = index;
          break;
        }
      }

      return { isSectionEnd, sectionElementIndex };
    }
    return { isSectionEnd, sectionElementIndex };
  };

  const checkIsParentBelow = (pageElements, currentIndex) => {
    let isParentElementBelow = false;
    for (let i = currentIndex + 1; i < pageElements.length; i++) {
      if (pageElements[i] && !pageElements[i].childReferenceId) {
        isParentElementBelow = true;
        break;
      }
    }
    // console.log({ currentIndex, isParentElementBelow });

    return isParentElementBelow;
  };

  const checkSectionOrOutsideSectionBelow = (pageElements, currentIndex) => {
    let isSectionBelow = false;
    if (pageElements[currentIndex].elementType !== 'section') return isSectionBelow;
    for (let i = currentIndex + 1; i < pageElements.length; i++) {
      if (!pageElements[i]) break;
      if (pageElements[i].elementType === 'section' || !pageElements[i].isInSection) {
        isSectionBelow = true;
        break;
      }
    }

    return isSectionBelow;
  };

  const getSectionEndIndex = (pageElements, currentIndex) => {
    let sectionEndIndex = currentIndex;
    for (let i = currentIndex + 1; i < pageElements.length; i++) {
      if (!pageElements[i]) break;
      if (pageElements[i].elementType === 'section' || !pageElements[i].isInSection) {
        sectionEndIndex = i - 1;
        break;
      }
    }
    return sectionEndIndex;
  };

  const checkSwapArrow = (position, elementType, fieldCategory) => {
    const firstIndex = fieldCategory === 'user-field' ? 1 : 0;
    const sectionElementType = fieldCategory === 'user-field' ? 'section' : 'supervisor_section';
    let showUpArrow = false;
    let showDownArrow = false;

    const pageElements =
      formProperties[fieldCategory === 'user-field' ? 'page' + currentPage : 'supervisorFields']
        .elements;

    /* Temp swapping arrows logic for supervisor fields */
    /* if (fieldCategory === 'supervisor-field') {
      if (position + 1 > 0) showUpArrow = true;
      if (position + 1 < pageElements.length) showDownArrow = true;
      return { showUpArrow, showDownArrow };
    } */
    /* End */

    // based on element on child and parent level
    if (elementType === sectionElementType) {
      if (position > firstIndex) {
        for (let i = position - 1; i >= firstIndex; i--) {
          // if (pageElements[i].elementType === sectionElementType) {
          if (!pageElements[i].childReferenceId && !pageElements[i].isInSection) {
            showUpArrow = true;
            break;
          }
        }
      }
      if (position < pageElements.length - 1) {
        for (let i = position + 1; i < pageElements.length; i++) {
          // if (pageElements[i].elementType === sectionElementType) {
          if (!pageElements[i].childReferenceId && !pageElements[i].isInSection) {
            showDownArrow = true;
            break;
          }
        }
      }
    } else {
      if (pageElements[position].isInSection) {
        for (let i = position - 1; i >= firstIndex; i--) {
          if (pageElements[i].elementType === sectionElementType) break;
          if (!pageElements[i].childReferenceId && pageElements[i].isInSection) {
            showUpArrow = true;
            break;
          }
        }
        for (let i = position + 1; i < pageElements.length; i++) {
          if (pageElements[i].elementType === sectionElementType) break;
          if (!pageElements[i].childReferenceId && pageElements[i].isInSection) {
            showDownArrow = true;
            break;
          }
        }
      } else {
        if (position > firstIndex) {
          for (let i = position - 1; i >= firstIndex; i--) {
            if (pageElements[i].childReferenceId) continue;
            if (!pageElements[i].isInSection) {
              showUpArrow = true;
              break;
            }
          }
        }
        if (position < pageElements.length - 1) {
          for (let i = position + 1; i < pageElements.length; i++) {
            if (pageElements[i].childReferenceId) continue;
            if (!pageElements[i].isInSection) {
              showDownArrow = true;
              break;
            }
          }
        }
      }
    }
    return { showUpArrow, showDownArrow };
  };

  const getPagesList = () => {
    const pagesList = [];
    let properties = cloneDeep(formProperties);
    Object.entries(properties).forEach(([key, value]) => {
      if (key.indexOf('page') === 0) {
        const pageElements = value.elements;
        const foundPage = pageElements.find((e) => e.elementType === 'page');
        if (foundPage)
          pagesList.push({ page: foundPage, pageNumber: parseInt(key.replace('page', '')) });
      }
    });
    return pagesList;
  };

  const selectUserComponent = (position, elementType) => {
    if (selectedView === 'list-view') toggleUserElement(position);
    if (selectedField?.type === 'user-field' && selectedField.position === position)
      setSelectedField(null);
    else setSelectedField(cloneDeep({ type: 'user-field', position, currentPage, elementType }));
  };

  const selectSupervisorComponent = (position, elementType) => {
    if (selectedView === 'list-view') toggleSupervisorElement(position);
    if (selectedField?.type === 'supervisor-field' && selectedField.position === position)
      setSelectedField(null);
    else
      setSelectedField(cloneDeep({ type: 'supervisor-field', position, currentPage, elementType }));
  };

  return (
    <>
      <div className='form-preview'>
        <div className='d-flex'>
          {fieldCategoryList.map((fieldCategory, index) => (
            <div
              key={index}
              className={`field-category-header${
                selectedFieldCategory === fieldCategory.name ? ' field-category-selected' : ''
              }`}
              onClick={() => {
                setSelectedField(null);
                setSelectedFieldCategory(fieldCategory.name);
                toggleAllElements(false);
              }}
            >
              {t(fieldCategory.text)}
            </div>
          ))}
        </div>
        <div>
          {selectedFieldCategory === 'user-field' ? (
            <div className='page-list'>
              {Array.from(Array(totalPages).keys()).map((index) => {
                const page = formProperties['page' + (index + 1)].elements.find(
                  (e) => e.elementType === 'page',
                );
                return (
                  <PageElement
                    key={index}
                    page={page}
                    currentPage={currentPage}
                    pageNumber={index + 1}
                    totalPages={totalPages}
                    onClick={() => {
                      pageUpdated(index + 1);
                      setSelectedField(
                        cloneDeep({
                          type: 'user-field',
                          position: 0,
                          currentPage: index + 1,
                          elementType: 'page',
                        }),
                      );
                    }}
                    changeFormElementOrder={changeFormElementOrder}
                  />
                );
              })}
              <div>
                <img src={plusIcon} />
                <label className='theme-text-green-1' onClick={addPage}>
                  Add Page
                </label>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className='view-options'>
            <div
              className={selectedView === 'full-view' ? 'selected' : ''}
              onClick={() => {
                setSelectedView('full-view');
                toggleAllElements(true);
                setSelectedField(null);
              }}
            >
              <img src={layersIcon} />
              <label>Full View</label>
            </div>
            <div
              className={selectedView === 'list-view' ? 'selected' : ''}
              onClick={() => {
                setSelectedView('list-view');
                toggleAllElements(false);
                setSelectedField(null);
              }}
            >
              <img src={dotPointsIcon} />
              <label>List View</label>
            </div>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-between align-items-center p-2'>
          {selectedFieldCategory === 'user-field' && (
            <FormInstructions
              formInstructions={formInstructions}
              formId={formId}
              formVersionId={formFirstVersionId}
              getFormDetail={getFormDetail}
            />
          )}
          <label className='theme-size-0_8'>{t('form_rearrange_elements')}</label>
        </div>
      </div>
      <div
        className='fields-playground'
        style={{
          direction: direction,
          ...(propertiesSectionHeight ? { height: `${propertiesSectionHeight - 121}px` } : {}),
        }}
      >
        <div
          className={`d-flex flex-column page-elements${
            selectedFieldCategory !== 'user-field' ? ' d-none' : ''
          }`}
        >
          {formProperties &&
            formProperties['page' + currentPage] &&
            formProperties['page' + currentPage].elements &&
            formProperties['page' + currentPage].elements.map((element, index) => {
              const { isSectionOpen } = checkSection(
                formProperties['page' + currentPage].elements,
                index,
                'user-field',
              );
              const { isSectionEnd } = checkUserSectionEnd(
                formProperties['page' + currentPage].elements,
                index,
              );
              const isParentBelow = checkIsParentBelow(
                formProperties['page' + currentPage].elements,
                index,
              );
              const isSectionOrParentBelow = checkSectionOrOutsideSectionBelow(
                formProperties['page' + currentPage].elements,
                index,
              );

              return (!isSectionOpen && element.elementType !== 'section' && element.isInSection) ||
                element.childReferenceId ? (
                <Fragment key={index}></Fragment>
              ) : (
                <Fragment key={index}>
                  <div className={element.isInSection ? ' mx-3 ps-3 border-left-green-1-h-2' : ''}>
                    {/* {element.elementType !== 'page' && index > 1 && (
                    <DropZone onComponentDrop={() => onComponentDrop(index - 1)} shrink />
                  )} */}
                    {element.elementType === 'page' ? (
                      <></>
                    ) : element.elementType === 'section' ? (
                      <SectionComponent
                        elementIndex={index}
                        section={element}
                        direction={direction}
                        selectComponent={selectUserComponent}
                        changeFormElementOrder={changeFormElementOrder}
                        isSelected={selectedView === 'full-view' || element.isOpen}
                        checkSwapArrow={checkSwapArrow}
                      />
                    ) : (
                      <UserFieldComponent
                        elementIndex={index}
                        field={element}
                        direction={direction}
                        depth={1}
                        selectComponent={selectUserComponent}
                        changeFormElementOrder={changeFormElementOrder}
                        isOpened={selectedView === 'full-view' || element.isOpen}
                        isSelected={index === selectedField?.position}
                        selectedField={selectedField}
                        dataSourcesList={dataSourcesList}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pagesList={getPagesList()}
                        pageElements={formProperties['page' + currentPage].elements}
                        gotoPageTriggerMapObj={formProperties.otherDetails.gotoPageTriggerMapObj}
                        addTriggerInElement={addTriggerInElement}
                        handleTriggerElementChange={handleTriggerElementChange}
                        addTriggerType={addTriggerType}
                        removeTriggerType={removeTriggerType}
                        setFieldDragged={setFieldDragged}
                        checkSwapArrow={checkSwapArrow}
                      />
                    )}
                    {/* {isInSection &&
                    formProperties['page' + currentPage].elements[index + 1]?.elementType ===
                      'section' && (
                      <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
                    )} */}
                  </div>
                  {/* {element.elementType !== 'page' &&
                    index < formProperties['page' + currentPage].elements.length - 1 && (
                      <div
                        className={
                          (element.elementType === 'section' || isInSection) &&
                          formProperties['page' + currentPage].elements[index + 1].elementType !==
                            'section' &&
                          (element.elementType !== 'section' || element.isOpen)
                            ? ' mx-3 ps-3 border-left-green-1-h-2'
                            : ''
                        }
                      >
                        <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
                      </div>
                    )} */}

                  {(index > 0 || (index === 0 && draggedElement?.elementType === 'section')) &&
                    index <= formProperties['page' + currentPage].elements.length - 1 &&
                    ((element.elementType === 'section' && element.isOpen) ||
                      (element.elementType !== 'section' &&
                        (isParentBelow || element.isInSection))) && (
                      <div
                        className={
                          element.isInSection || element.elementType === 'section'
                            ? 'mx-3 ps-3 border-left-green-1-h-2'
                            : ''
                        }
                      >
                        {/* {console.log(index, element.isInSection)}
                        {console.log(
                          index > 0,
                          index < formProperties['page' + currentPage].elements.length - 1,
                          isInSection,
                          isParentBelow,
                        )} */}
                        <DropZone
                          onComponentDrop={() =>
                            fieldDragged
                              ? onMoveElement(
                                  index,
                                  element.isInSection || element.elementType === 'section',
                                )
                              : onComponentDrop(
                                  index,
                                  element.isInSection || element.elementType === 'section',
                                )
                          }
                          clearDrag={clearDrag}
                          shrink
                          // dropNotAllowed={
                          //   draggedElement?.elementType === 'section' &&
                          //   (element.isInSection || element.elementType === 'section')
                          // }
                          dropAllowed={
                            !!fieldDragged ||
                            !!(
                              draggedElement &&
                              (draggedElement.elementType !== 'section' ||
                                (draggedElement.elementType === 'section' &&
                                  !element.isInSection &&
                                  element.elementType !== 'section'))
                            )
                          }
                        />
                      </div>
                    )}
                  {/* {console.log({
                    index,
                    isSectionEnd,
                    isParentBelow,
                    0: element.elementType === 'section' && !element.isOpen,
                  })} */}
                  {/* {(isSectionEnd ||
                    (element.elementType === 'section' &&
                      ((!element.isOpen && isParentAfterSection) ||
                        (element.isOpen && isParentAfterSection)))) &&    // last logic
                    isParentBelow && (
                      <div>
                        <DropZone
                          onComponentDrop={() => onComponentDrop(index)}
                          shrink
                          isForSection
                        />
                      </div>
                    )} */}
                  {index > 0 &&
                    (isSectionEnd ||
                      (element.elementType === 'section' &&
                        !element.isOpen &&
                        isSectionOrParentBelow)) &&
                    isParentBelow && (
                      <DropZone
                        onComponentDrop={() =>
                          fieldDragged
                            ? onMoveElement(
                                index,
                                false,
                                element.elementType === 'section' && !element.isOpen,
                              )
                            : onComponentDrop(
                                index,
                                false,
                                element.elementType === 'section' && !element.isOpen,
                              )
                        }
                        clearDrag={clearDrag}
                        shrink
                        outsideSection
                        dropAllowed={!!fieldDragged || !!draggedElement}
                      />
                    )}
                </Fragment>
              );
            })}
        </div>
        <div
          className={`d-flex flex-column supervisor-elements${
            selectedFieldCategory !== 'supervisor-field' ? ' d-none' : ''
          }`}
        >
          {formProperties &&
            formProperties.supervisorFields.elements.map((element, index) => {
              const { isSectionOpen } = checkSection(
                formProperties.supervisorFields.elements,
                index,
                'supervisor-field',
              );
              const { isSectionEnd, sectionElementIndex } = checkSectionEnd(
                formProperties.supervisorFields.elements,
                'supervisor-field',
                index,
              );
              const isParentBelow = checkIsParentBelow(
                formProperties.supervisorFields.elements,
                index,
              );
              // const isSectionOrParentBelow = checkSectionOrOutsideSectionBelow(
              //   formProperties.supervisorFields.elements,
              //   index,
              // );

              return (!isSectionOpen &&
                element.elementType !== 'supervisor_section' &&
                element.isInSection) ||
                element.childReferenceId ? (
                <Fragment key={index}></Fragment>
              ) : (
                <Fragment key={index}>
                  {index === 0 && draggedElement?.elementType === 'supervisor_section' && (
                    <DropZone
                      onComponentDrop={() => onComponentDrop(-1)}
                      clearDrag={clearDrag}
                      shrink
                      outsideSection
                      dropAllowed={!!(draggedElement?.elementType === 'supervisor_section')}
                    />
                  )}
                  <div className={element.isInSection ? ' mx-3 ps-3 border-left-green-1-h-2' : ''}>
                    {element.elementType === 'supervisor_section' ? (
                      <SupervisorSectionComponent
                        elementIndex={index}
                        section={element}
                        direction={direction}
                        selectComponent={selectSupervisorComponent}
                        changeFormElementOrder={changeFormElementOrder}
                        isSelected={selectedView === 'full-view' || element.isOpen}
                        checkSwapArrow={checkSwapArrow}
                      />
                    ) : (
                      <SupervisorFieldComponent
                        elementIndex={index}
                        field={element}
                        selectComponent={selectSupervisorComponent}
                        direction={direction}
                        changeFormElementOrder={changeFormElementOrder}
                        isOpened={selectedView === 'full-view' || element.isOpen}
                        isSelected={index === selectedField?.position}
                        setFieldDragged={setFieldDragged}
                        checkSwapArrow={checkSwapArrow}
                        handleTriggerElementChange={handleTriggerElementChange}
                        addTriggerType={addTriggerType}
                        depth={1}
                        addTriggerInElement={addTriggerInElement}
                        removeTriggerType={removeTriggerType}
                        selectedField={selectedField}
                        supervisorElements={formProperties.supervisorFields.elements}
                        // currentPage={currentPage}
                        // setFieldDragged={setFieldDragged}
                      />
                    )}
                  </div>
                  {((element.elementType === 'supervisor_section' && element.isOpen) ||
                    element.elementType !== 'supervisor_section') && (
                    <div
                      className={
                        element.isInSection || element.elementType === 'supervisor_section'
                          ? 'mx-3 ps-3 border-left-green-1-h-2'
                          : ''
                      }
                    >
                      <DropZone
                        onComponentDrop={() =>
                          fieldDragged
                            ? onMoveElement(
                                index,
                                element.isInSection || element.elementType === 'supervisor_section',
                              )
                            : onComponentDrop(
                                index,
                                element.isInSection || element.elementType === 'supervisor_section',
                              )
                        }
                        shrink
                        dropAllowed={
                          !!fieldDragged ||
                          !!(draggedElement && draggedElement.elementType !== 'supervisor_section')
                        }
                        clearDrag={clearDrag}
                      />
                      {/* {checkSupervisorSectionEnd(formProperties.supervisorFields.elements, index)
                    .isSectionEnd && (
                    <div className='theme-background-green-4 px-3 py-2'>
                      {checkSupervisorSectionEnd(
                        formProperties.supervisorFields.elements,
                        index,
                      ).workflows.map((_, workflowIndex) => (
                        <div key={workflowIndex}></div>
                      ))}
                      <div
                        className='d-flex gap-1 align-items-center border-green-1-h-2 rounded-sm p-1 width-max-content'
                        onClick={() => addWorkflowInSection(index)}
                      >
                        <img src={plusIcon} height={15} />
                        <label className='theme-text-green-1 theme-size-0_9 cursor-pointer'>
                          Add Workflow Logic
                        </label>
                        <label></label>
                      </div>
                    </div>
                  )} */}
                      {isSectionEnd && (
                        <SectionWorkFlowContainer
                          supervisorElements={formProperties.supervisorFields.elements}
                          sectionElementIndex={sectionElementIndex}
                          addWorkflowInSection={addWorkflowInSection}
                          handleSectionWorkflowChange={handleSectionWorkflowChange}
                        />
                      )}
                    </div>
                  )}
                  {(isSectionEnd ||
                    (element.elementType === 'supervisor_section' && !element.isOpen)) &&
                    isParentBelow && (
                      <DropZone
                        onComponentDrop={() =>
                          fieldDragged
                            ? onMoveElement(
                                index,
                                false,
                                element.elementType === 'supervisor_section' && !element.isOpen,
                              )
                            : onComponentDrop(
                                index,
                                false,
                                element.elementType === 'supervisor_section' && !element.isOpen,
                              )
                        }
                        clearDrag={clearDrag}
                        shrink
                        outsideSection
                        dropAllowed={!!(draggedElement?.elementType === 'supervisor_section')}
                      />
                    )}
                </Fragment>
              );
            })}
        </div>
        <DropZone
          onComponentDrop={() =>
            fieldDragged
              ? onMoveElement(
                  fieldDragged.fieldCategory === 'user-field'
                    ? formProperties['page' + currentPage].elements.length - 1
                    : formProperties.supervisorFields.elements.length - 1,
                  false,
                  false,
                )
              : onComponentDrop(
                  -2,
                  draggedElement.fieldCategory === 'supervisor-field' &&
                    draggedElement.elementType !== 'supervisor_section'
                    ? true
                    : false,
                  false,
                )
          }
          outsideSection
          dropAllowed={
            !!(
              fieldDragged &&
              (selectedFieldCategory !== 'supervisor-field' ||
                fieldDragged.elementType === 'supervisor_section')
            ) ||
            !!(
              draggedElement &&
              (selectedFieldCategory !== 'supervisor-field' ||
                draggedElement.elementType === 'supervisor_section')
            )
          }
          clearDrag={clearDrag}
        />
        {statusFieldAlreadyAdded && (
          <ConfirmationModal
            show={statusFieldAlreadyAdded}
            title={t('alert_form_element_status_already_added')}
            message={t('alert_form_element_status_already_added_detail')}
            actionList={[
              {
                color: 'black-1',
                text: t('button_ok'),
                onClick: () => setStatusFieldAlreadyAdded(false),
              },
            ]}
          />
        )}
      </div>
    </>
  );
}
FormPreview.propTypes = {
  selectedFieldCategory: PropTypes.string.isRequired,
  setSelectedFieldCategory: PropTypes.func.isRequired,
  draggedElement: PropTypes.object,
  setDraggedElement: PropTypes.func,
  selectedField: PropTypes.object,
  direction: PropTypes.string.isRequired,
  formProperties: PropTypes.object.isRequired,
  setFormProperties: PropTypes.func.isRequired,
  setSelectedField: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  propertiesSectionRef: PropTypes.object.isRequired,
  formInstructions: PropTypes.object,
  formId: PropTypes.string.isRequired,
  formFirstVersionId: PropTypes.string.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};
export default FormPreview;
