import * as yup from 'yup';
import sa from 'assets/images/icons/flags/sa.svg';
import pk from 'assets/images/icons/flags/pk.svg';
import eg from 'assets/images/icons/flags/eg.svg';

import { AuthReducer } from 'redux/auth';
import store from 'store/store';
import { CallAPI, GenerateRSAKeys, GetOtpForOperation } from 'actions/General';
import NodeRSA from 'node-rsa';
const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

export const loginSchema = yup.object({
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required'),
});

export const registerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email address is required'),
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required').min(8),
  check: yup.boolean().isTrue('Mark check terms and conditions'),
});

export const otpSchema = yup.object({
  otp: yup.string().required('OTP is required').length(4),
});

export const resetPasswordSchema = yup.object({
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
});

export const newPasswordSchema = yup.object({
  password: yup.string().required('Password is required').min(8),
  rePassword: yup
    .string()
    .required('Password is required')
    .min(8)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const countryList = [
  {
    value: '+966',
    icon: sa,
    displayValue: 'select_saudi_arabia',
  },
  {
    value: '+92',
    icon: pk,
    displayValue: 'select_pakistan',
  },
  {
    value: '+20',
    icon: eg,
    displayValue: 'select_egypt',
  },
];

export const userLogin = async (
  data,
  setApiError,
  setLoading,
  setOtpRequired,
  setPublicKey,
  navigate,
) => {
  const result = await CallAPI(
    'LOGIN_USER',
    {
      countryCode: data.countryCode,
      mobileNumber: data.mobileNumber,
      password: await encryptPassword(data.password),
    },
    null,
    null,
    setApiError,
    null,
  );
  console.log('result', result);
  if (result.status) {
    if (!result.data.hasPassword) {
      navigate('/reset_password', {
        relative: 'path',
        state: { countryCode: data.countryCode, mobileNumber: data.mobileNumber },
      });
    } else if (result.data.status === 'pending') {
      setOtpRequired(true);
      const otpResult = await GetOtpForOperation(
        { otpType: 'register', countryCode: data.countryCode, mobileNumber: data.mobileNumber },
        setApiError,
        setLoading,
      );
      if (otpResult.status) {
        setPublicKey(otpResult.publicKey);
      }
    } else {
      dispatch(
        AuthAction.updateAuthState({
          loginToken: result.data.access_token,
          loginRefreshToken: result.data.refresh_token,
        }),
      );
      navigate('/all_companies');
    }
  }
};

export const checkUserExist = async (data, setApiError, setLoading) => {
  let result = await CallAPI('CHECK_USER_EXIST', data, null, setLoading, setApiError, null);
  if (result.status) return true;
  else setApiError('user does not exist');
};

export const verifyOTP = async (data, setApiError, setLoading, type, setStep = undefined) => {
  let result = await CallAPI(
    type === 'register' ? 'VERIFY_REGISTER_OTP' : 'VERIFY_PASSWORD_OTP',
    {
      countryCode: data.countryCode,
      mobileNumber: data.mobileNumber,
      otp: await encryptOTP(data.otp, data.publicKey),
    },
    null,
    setLoading,
    setApiError,
    null,
  );

  if (result.status) {
    if (setStep) setStep(3);
    return true;
  } else {
    setApiError('invalid otp');
  }
};

export const verifyOTPAndDeleteCompany = async (data, setApiError, setLoading) => {
  const encryptedOTP = await encryptOTP(data.otp, data.publicKey);
  let result = await CallAPI(
    'VERIFY_DELETE_COMPANY_OTP',
    { otp: encryptedOTP },
    null,
    setLoading,
    setApiError,
  );

  if (result.status) {
    const deleteResponse = await CallAPI(
      'DELETE_COMPANY',
      { otp: encryptedOTP, validationKey: result.data.validationKey },
      null,
      setLoading,
      setApiError,
    );
    if (deleteResponse.status) return true;
  } else {
    setApiError('invalid otp');
  }
};

export const userRegister = async (data, setApiError, setLoading, setPublicKey, setAskForOtp) => {
  setLoading(true);
  const { publicKey, privateKey } = GenerateRSAKeys();
  const result = await CallAPI(
    'REGISTER_USER',
    {
      firstName: data.firstName,
      lastName: data.lastName,
      password: await encryptPassword(data.password),
      countryCode: data.countryCode,
      mobileNumber: data.mobileNumber,
      privateKey,
    },
    null,
    setLoading,
    setApiError,
    null,
  );
  if (result.status) {
    setPublicKey(publicKey);
    setAskForOtp(true);
  }
};

export const encryptPassword = async (password) => {
  // decoding base64 public key
  // eslint-disable-next-line no-undef
  const SERVER_PUBLIC_KEY = atob(process.env.REACT_APP_SERVER_PUBLIC_KEY);
  try {
    const publicKey = new NodeRSA(SERVER_PUBLIC_KEY, 'pkcs1-public', {
      encryptionScheme: {
        hash: 'sha1',
      },
    });

    const encryptedData = publicKey.encrypt(password, 'base64');
    return encryptedData;
  } catch (err) {
    console.log('Encryption failed', err);
  }
};

export const encryptOTP = async (password, otpPublicKey) => {
  try {
    const publicKey = new NodeRSA(otpPublicKey, 'pkcs1-public', {
      encryptionScheme: { hash: 'sha1' },
    });

    const encryptedData = publicKey.encrypt(password, 'base64');
    return encryptedData;
  } catch (err) {
    console.log('Encryption failed', err);
  }
};
