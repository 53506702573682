import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InstructionModal from './InstructionModal';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import { getImageUrl } from '../actions';

const FormInstructions = ({ formInstructions, formId, formVersionId, getFormDetail }) => {
  const { t } = useTranslation();
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [existingImgUrl, setExistingImgUrl] = useState(null);

  const getExistingMedia = () => {
    let imageMedia,
      pdfMedia = null;
    formInstructions?.media?.forEach(({ _id, ...m }) => {
      if (m.mediaType === 'image') imageMedia = { id: _id, ...m };
      if (m.mediaType === 'file') pdfMedia = { id: _id, ...m };
    });
    return { imageMedia, pdfMedia };
  };
  const [instructionData, setInstructionData] = useState({
    isEnabled: formInstructions?.isEnabled ?? false,
    description: formInstructions?.description ?? '',
    formId,
    formVersionId,
    imageFile: null,
    pdfFile: null,
    ...getExistingMedia(),
    oldExist: !!formInstructions,
  });

  const getExistingImgUrl = async () => {
    const imageMedia = getExistingMedia().imageMedia;
    const imageUrl = imageMedia ? await getImageUrl(imageMedia.id) : null;
    setExistingImgUrl(imageUrl || null);
  };

  const refreshFormInstructions = () => {
    setInstructionData((prevState) => ({
      ...prevState,
      description: formInstructions?.description ?? '',
      imageFile: null,
      pdfFile: null,
      ...getExistingMedia(),
      oldExist: !!formInstructions,
    }));
    // get getExistingImgUrl to show image in instructionModal
    getExistingImgUrl();
  };

  const handleInstructionChange = (field, value) => {
    if (field === 'isEnabled' && !showInstructionModal.isEnabled && value)
      setShowInstructionModal(true);
    setInstructionData((prev) => ({ ...prev, [field]: value }));
  };

  const handleResetInstructions = () => {
    setInstructionData((prev) => ({
      ...prev,
      description: formInstructions?.description ?? '',
      imageFile: null,
      pdfFile: null,
    }));
  };

  const handleCancelInstructions = () => {
    setInstructionData((prev) => ({
      ...prev,
      isEnabled: formInstructions?.isEnabled ?? false,
      description: formInstructions?.description ?? '',
      imageFile: null,
      pdfFile: null,
    }));
    setShowInstructionModal(false);
  };

  return (
    <Fragment>
      <div className='d-flex gap-2 align-items-center p-2'>
        <ToggleSwitch
          name='isEnabled'
          value={instructionData.isEnabled}
          handleChange={handleInstructionChange}
        />
        {instructionData.isEnabled ? (
          <label
            className='theme-size-0_8 cursor-pointer theme-text-green-1'
            onClick={() => setShowInstructionModal(true)}
          >
            {t('form_manage_instructions')}
          </label>
        ) : (
          <label className='theme-size-0_8'>{t('form_enable_instructions')}</label>
        )}
      </div>
      {showInstructionModal && (
        <InstructionModal
          showInstructionModal={true}
          setShowInstructionModal={setShowInstructionModal}
          instructionData={instructionData}
          existingImgUrl={existingImgUrl}
          handleInstructionChange={handleInstructionChange}
          handleResetInstructions={handleResetInstructions}
          refreshFormInstructions={refreshFormInstructions}
          handleCancelInstructions={handleCancelInstructions}
          getFormDetail={getFormDetail}
        />
      )}
    </Fragment>
  );
};

FormInstructions.propTypes = {
  formInstructions: PropTypes.object,
  formId: PropTypes.string.isRequired,
  formVersionId: PropTypes.string.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};

export default FormInstructions;
