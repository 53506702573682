import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { useTranslation } from 'react-i18next';
import IconSelectionField from 'components/form-components/IconSelectionField';
import layoutBottomWhiteIcon from 'assets/images/icons/layout-bottom-white.svg';
import layoutTopWhiteIcon from 'assets/images/icons/layout-top-white.svg';
import CustomMentionsComponent from 'components/form-components/CustomMentionsComponent';

function WatermarkComponent({
  fieldSectionProperties,
  singleProperty,
  toggleBox,
  onChangeProperties,
  formElemetsOptionArray,
}) {
  const { t } = useTranslation();
  const [resetMentionsInput, setResetMentionsInput] = useState(false);

  const systemElementsHeadingArray = [
    {
      id: 'Please-select-contents:',
      display: t('form_field_watermark_dropdown_Please select contents'),
      value: 'Please-select-contents:',
      type: 'heading',
    },
    {
      id: 'options:',
      display: t('form_field_watermark_dropdown_options'),
      value: 'options:',
      type: 'heading',
    },
  ];
  const systemElementsOptionArray = [
    {
      id: 'user-name',
      display: t('form_field_watermark_dropdown_User Name'),
      value: 'user-name',
      type: 'systemField',
    },
    {
      id: 'date-time',
      display: t('form_field_watermark_dropdown_Date And Time'),
      value: 'date-time',
      type: 'systemField',
    },
  ];
  const formElementsHeadingArray = [
    {
      id: 'field-ids:',
      display: t("form_field_watermark_dropdown_Field ID's"),
      value: 'field-ids:',
      type: 'heading',
    },
  ];

  const iconOptions = [
    { value: 'bottom', icon: layoutBottomWhiteIcon },
    { value: 'top', icon: layoutTopWhiteIcon },
  ];

  const updateMentionsMap = (updatedObject) => {
    fieldSectionProperties.watermarkUsedFieldsMap = { ...updatedObject };
  };

  return (
    <>
      <div className='d-flex'>
        <SingleCheckBoxField
          disabled={!(fieldSectionProperties.allowPictures || fieldSectionProperties.allowVideos)}
          name={singleProperty.key}
          value={fieldSectionProperties[singleProperty.key]}
          handleChange={(name, value) => {
            onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
          }}
          text={<span>{t(singleProperty.text)}</span>}
          shrink
        />
        {fieldSectionProperties[singleProperty.key] && (
          <span
            className={`user-select-none ${
              fieldSectionProperties['watermarkText']
                ? 'theme-text-green-1 cursor-pointer'
                : 'text-secondary opacity-50'
            }`}
            onClick={() =>
              fieldSectionProperties['watermarkText'] && setResetMentionsInput((prev) => !prev)
            }
          >
            Reset
          </span>
        )}
      </div>

      {fieldSectionProperties[singleProperty.key] ? (
        <div className='mt-3 ms-4'>
          <CustomMentionsComponent
            name={'watermarkText'}
            placeholder={t('form_field_watermark_placeholder_text')}
            value={fieldSectionProperties['watermarkText']}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            updateMentionsMap={updateMentionsMap}
            resetMentionsInput={resetMentionsInput}
            setResetMentionsInput={setResetMentionsInput}
            systemElementsHeadingArray={systemElementsHeadingArray}
            systemElementsOptionArray={systemElementsOptionArray}
            formElementsHeadingArray={formElementsHeadingArray}
            formElemetsOptionArray={formElemetsOptionArray}
          />

          <IconSelectionField
            name={'watermarkAlignment'}
            value={fieldSectionProperties['watermarkAlignment']}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            label={'form_field_watermark_alignment'}
            classes='w-100'
            iconOptions={iconOptions}
            shrink
          />
          <SingleCheckBoxField
            name={'watermarkTextCenter'}
            classes='mt-2'
            value={fieldSectionProperties['watermarkTextCenter']}
            handleChange={(name, value) => {
              onChangeProperties(name, value, toggleBox.defaultPropsKey + 'Properties');
            }}
            text={<span>{t('Show Text on Center')}</span>}
            shrink
          />
        </div>
      ) : null}
    </>
  );
}
WatermarkComponent.propTypes = {
  fieldSectionProperties: PropTypes.object,
  singleProperty: PropTypes.object,
  toggleBox: PropTypes.object,
  onChangeProperties: PropTypes.func,
  formElemetsOptionArray: PropTypes.array,
};
export default WatermarkComponent;
