import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import tablePropertiesIcon from 'assets/images/icons/table-properties.svg';
import {
  defaultMediaProperties,
  fieldTypeAllowedProperties,
  formElementsDefaultProperties,
} from './utils';

import SingleFieldProperties from './SingleFieldProperties';
import { updateSavedChanges } from 'config/config';
import { removeMappingFromPageMapObject, removeSingleTriggerType } from './actions';

function FormProperties({
  selectedField,
  setSelectedField,
  formProperties,
  setFormProperties,
  toggleList,
  isNestedOption,
  dataSourcesList,
}) {
  const { t } = useTranslation();

  const [elementPropertiesType, setElementPropertiesType] = useState(toggleList);
  const [selectedElementIndex, setSelectedElementIndex] = useState(-1);

  useEffect(() => {
    let list = toggleList;
    if (selectedField && selectedField.elementType) {
      list = toggleList.filter((singleTab) => {
        if (singleTab.excludedFor.indexOf(selectedField.elementType) === -1) return true;
        else return false;
      });
      setSelectedElementIndex(selectedField.position);
    } else {
      setSelectedElementIndex(-1);
    }
    setElementPropertiesType(list);
  }, [selectedField]);

  const rightToggleBoxOpen = (index) => {
    const elementsList = cloneDeep(elementPropertiesType);
    elementsList[index].isOpen = !elementsList[index].isOpen;
    setElementPropertiesType(elementsList);
  };
  const onChangeProperties = (key, value, type) => {
    updateSavedChanges(true, 'FORM_DESIGN');
    // console.log({ key, value, type });

    let formElementProperties = cloneDeep(formProperties);

    let elementAddedIn =
      selectedField.type === 'user-field' ? 'page' + selectedField.currentPage : 'supervisorFields';

    let elementProperties =
      formElementProperties[elementAddedIn].elements[selectedField.position].properties;
    if (selectedField.elementType === 'dateTime' && selectedField.type === 'user-field') {
      if (elementProperties.validationProperties['minRange']) {
        delete elementProperties.validationProperties['minRange'];
        delete elementProperties.validationProperties['maxRange'];
      }
    } else if (key === 'required' && value) {
      elementProperties[type]['readOnly'] = false;
      elementProperties[type]['hidden'] = false;
      elementProperties[type]['hideOnPdf'] = false;
    } else if (key === 'hidden' && value) {
      elementProperties[type]['required'] = false;
      elementProperties[type]['readOnly'] = false;
      elementProperties[type]['hideOnPdf'] = false;
    } else if (key === 'dataSource' && elementProperties[type].dataSource !== value) {
      elementProperties[type].displayColumn = '';
      elementProperties[type].valueColumn = '';
      elementProperties[type].bindingList = [];
    } else if (key === 'dataSourceType') {
      const elementType = value === 'internal' ? 'dataSource' : 'externalApi';
      setSelectedField((prev) => ({ ...prev, elementType }));
      formElementProperties[elementAddedIn].elements[selectedField.position].elementType =
        elementType;
      const baseProps = cloneDeep(
        value === 'internal'
          ? formElementsDefaultProperties.dataSource.basicProperties
          : formElementsDefaultProperties.externalApi.basicProperties,
      );
      const modifiedBaseProps = {
        ...baseProps,
        fieldTitle: elementProperties.basicProperties.fieldTitle,
        fieldHintText: elementProperties.basicProperties.fieldHintText,
        fieldPlaceholder: elementProperties.basicProperties.fieldPlaceholder,
        dataType: elementProperties.basicProperties.dataType,
        dataSourceType: elementProperties.basicProperties.dataSourceType,
      };
      elementProperties.basicProperties = { ...modifiedBaseProps };
    } else if (key === 'triggerType') {
      if (value !== 'onchange_other') {
        elementProperties[type].onChangeTriggerFieldId = '';
      }
      if (!['onload', 'onchange_other'].includes(value)) {
        elementProperties[type].selfFillColumnKey = '';
      }
    } else if (key === 'onChangeTriggerFieldId') {
      const currentValue = elementProperties[type][key];
      // If tries to set trigger field id to itself then set previous value
      if (
        formElementProperties[elementAddedIn].elements[selectedField.position].elementGlobalId ===
        value
      )
        value = currentValue;
    } else if (key === 'bindingList') {
      let selectedElement = formElementProperties[elementAddedIn].elements[selectedField.position];
      let formBindingObj = {
        ...formElementProperties.otherDetails.bindingElementsObj,
        [value.fieldId]: {
          fieldName: elementProperties[type].fieldTitle,
          fieldId: selectedElement.elementGlobalId
            ? selectedElement.elementGlobalId
            : selectedElement.uniqueIdToReplace,
        },
      };
      value = [...elementProperties[type].bindingList, value];
      formElementProperties.otherDetails.bindingElementsObj = formBindingObj;
    } else if (key === 'fieldTitle' && selectedField.type === 'user-field') {
      let selectedElement = formElementProperties[elementAddedIn].elements[selectedField.position];
      let updatedElementId = selectedElement.elementGlobalId
        ? selectedElement.elementGlobalId
        : selectedElement.uniqueIdToReplace;
      if (formElementProperties.otherDetails.allFormElementsObj[updatedElementId])
        formElementProperties.otherDetails.allFormElementsObj = {
          ...formElementProperties.otherDetails.allFormElementsObj,
          [updatedElementId]: {
            fieldName: value ? value : 'UnTitled',
            fieldId: updatedElementId,
          },
        };
      if (formElementProperties.otherDetails.onChangeTriggerElementsObj[updatedElementId])
        formElementProperties.otherDetails.onChangeTriggerElementsObj = {
          ...formElementProperties.otherDetails.onChangeTriggerElementsObj,
          [updatedElementId]: {
            fieldName: value ? value : 'UnTitled',
            fieldId: updatedElementId,
          },
        };
    } else if (key === 'removeBindingItem') {
      key = 'bindingList';
      const removedIndex = value.index;
      value = elementProperties[type][key];
      value.splice(removedIndex, 1);
      delete formElementProperties.otherDetails.bindingElementsObj[value.removedFieldId];
    } else if (key === 'requiredPictures' && value) {
      elementProperties[type].minPictures =
        parseInt(elementProperties[type].minPictures) > 1 ? elementProperties[type].minPictures : 1;
    } else if (
      [
        'minRange',
        'maxRange',
        'videoMinutes',
        'videoSeconds',
        'minPictures',
        'maxPictures',
      ].includes(key)
    ) {
      value = Number(value);
    } else if (
      (key === 'allowPictures' || key === 'allowVideos' || key === 'enableWatermark') &&
      !value
    ) {
      // reset watermark properties when value of enableWatermark checkbox or if both("allowPictures" and "allowVideos") are false
      let resetWatermarkProperties = false;
      if (key === 'enableWatermark') {
        resetWatermarkProperties = true;
      } else {
        const otherKey = key === 'allowPictures' ? 'allowVideos' : 'allowPictures';
        if (elementProperties[type][otherKey] === false) {
          elementProperties[type]['enableWatermark'] = false;
          resetWatermarkProperties = true;
        }
      }
      if (resetWatermarkProperties) {
        elementProperties[type]['watermarkText'] = defaultMediaProperties.watermarkText;
        elementProperties[type]['watermarkAlignment'] = defaultMediaProperties.watermarkAlignment;
        elementProperties[type]['watermarkTextCenter'] = defaultMediaProperties.watermarkTextCenter;
        elementProperties[type]['watermarkUsedFieldsMap'] =
          defaultMediaProperties.watermarkUsedFieldsMap;
      }
    } else if (key === 'optionList') {
      if (Array.isArray(value) && value.length === 0) {
        elementProperties[type]['setName'] = undefined;
      }
      if (!Array.isArray(value) && value.setName) {
        elementProperties[type]['setName'] = value.setName;
        value = value.optionList;
      }
    } else if (key === 'geofence' && !value) {
      elementProperties[type]['geofenceRange'] = 5;
      elementProperties[type]['geofenceRestricted'] = false;
      elementProperties[type]['geofenceEnableDirection'] = false;
    }
    let previousOptionList = [];
    // store previous option list
    if (key === 'optionList') {
      previousOptionList = elementProperties[type][key];
      // console.log('abc2', previousOptionList);
    }
    elementProperties[type][key] = value;
    formElementProperties[elementAddedIn].elements[selectedField.position].properties =
      elementProperties;

    const currentPageElement =
      formElementProperties[elementAddedIn].elements.find((e) => e.elementType === 'page') ?? null;

    if (key === 'optionList') {
      checkAndDeleteTriggersIfNeeded(
        previousOptionList,
        value,
        formElementProperties[elementAddedIn].elements,
        selectedField.position,
        currentPageElement,
        formElementProperties.otherDetails.gotoPageTriggerMapObj,
      );
    }
    setFormProperties(formElementProperties);
  };

  const removeAllTriggers = (elementsList, elementIndex, currentPageId, gotoPageTriggerMapObj) => {
    setSelectedField(null);

    const elementTriggers = elementsList[elementIndex].triggers;

    if (elementTriggers?.length) {
      for (let triggerIndex = elementTriggers.length - 1; triggerIndex >= 0; triggerIndex--) {
        const triggerTypes = elementTriggers[triggerIndex].types;
        for (let typeIndex = triggerTypes.length - 1; typeIndex >= 0; typeIndex--) {
          const triggerType = triggerTypes[typeIndex];
          if (currentPageId && triggerType.type === 'user_page') {
            removeMappingFromPageMapObject(gotoPageTriggerMapObj, currentPageId, [
              triggerType.elementGlobalId ?? triggerType.triggerFlowReferenceId,
            ]);
          }
          removeSingleTriggerType(elementsList, elementIndex, triggerIndex, typeIndex);
        }
      }
      if (elementTriggers.every((t) => t.types.length === 0))
        elementsList[elementIndex].triggers = null;
    }
  };

  const isOptionsChanged = (previousOptionList, newOptionList) => {
    const fieldOptionValues = previousOptionList.map((o) => o.value);
    // const fieldOptionText = previousOptionList.map((o) => o.responseText);
    const currentOptionValues = newOptionList.map((o) => o.value);
    // const currentOptionText = newOptionList.map((o) => o.displayValue);

    if (fieldOptionValues.length !== currentOptionValues.length) return 0;
    else if (!fieldOptionValues.every((v) => currentOptionValues.includes(v))) return 0;
    // else if (!fieldOptionText.every((v) => currentOptionText.includes(v))) return 1;
    return 2;
  };

  const checkAndDeleteTriggersIfNeeded = (
    previousOptionList,
    newOptionList,
    elementsList,
    elementIndex,
    currentPageElement,
    gotoPageTriggerMapObj,
  ) => {
    const isChanged = isOptionsChanged(previousOptionList, newOptionList);
    console.log('abc', previousOptionList, newOptionList, isChanged, 'removeALlTriggers');

    if (isChanged < 1) {
      const currentPageId =
        currentPageElement?.elementGlobalId ?? currentPageElement?.triggerFlowReferenceId ?? null;
      removeAllTriggers(elementsList, elementIndex, currentPageId, gotoPageTriggerMapObj);
    }
  };

  const checkMultiChoiceDisabled = (key) => {
    return selectedField.elementType === 'checkList' &&
      key === 'multipleChoice' &&
      formProperties['page' + selectedField.currentPage].elements[selectedField.position].triggers
        ?.length
      ? true
      : false;
  };

  const checkGeofenceToggleEnabled = (key) => {
    let geofenceEnable = false;
    let anyLocationElementGeofenceFound = false;
    if (selectedField.elementType === 'location' && key === 'geoCoordinates') {
      const selectedFormElement =
        formProperties['page' + selectedField.currentPage].elements[selectedField.position];
      const selectedFormFieldId = selectedFormElement?.elementGlobalId
        ? selectedFormElement['elementGlobalId']
        : selectedFormElement['uniqueIdToReplace'];

      if (selectedFormFieldId) {
        const bindingObject = formProperties?.otherDetails?.bindingElementsObj;

        if (bindingObject[selectedFormFieldId]) {
          const datasourceFormFieldId = bindingObject[selectedFormFieldId].fieldId;

          const formPropertiesKeys = Object.keys(formProperties);

          for (let i = 0; i < formPropertiesKeys.length; i++) {
            const key = formPropertiesKeys[i];

            if (key.startsWith('page')) {
              const dataSourceElement = formProperties[key].elements.find((element) => {
                const datasourceId = element['elementGlobalId']
                  ? element['elementGlobalId']
                  : element['uniqueIdToReplace'];
                return datasourceId === datasourceFormFieldId;
              });

              if (dataSourceElement) {
                const dataSourceId =
                  dataSourceElement?.properties?.basicProperties?.dataSource ?? null;

                const dataSourceColumnId =
                  dataSourceElement?.properties?.basicProperties?.bindingList.find(
                    (bindingObj) => bindingObj.fieldId === selectedFormFieldId,
                  )?.columnId;

                if (dataSourceColumnId) {
                  if (dataSourceElement.elementType === 'externalApi') {
                    geofenceEnable = true;
                    break;
                  }
                  if (dataSourceId) {
                    const bindedDataSource = dataSourcesList.find(
                      (datasource) => datasource.value === dataSourceId,
                    );

                    if (bindedDataSource) {
                      geofenceEnable = bindedDataSource.columns.some(
                        (column) =>
                          column.value === dataSourceColumnId && column.type === 'geoCoordinates',
                      );
                    }
                  }
                }
                break;
              }
            }
          }
        }
      }
      if (geofenceEnable) {
        const formPropertiesKeys = Object.keys(formProperties);
        const allLocationElements = [];
        for (let i = 0; i < formPropertiesKeys.length; i++) {
          const key = formPropertiesKeys[i];

          if (key.startsWith('page')) {
            const locationElements = formProperties[key].elements.filter(
              (element) =>
                element.elementType === 'location' &&
                element[
                  selectedFormElement['elementGlobalId']
                    ? 'elementGlobalId'
                    : selectedFormElement['uniqueIdToReplace'] && 'uniqueIdToReplace'
                ] !== selectedFormFieldId,
            );

            allLocationElements.push(...locationElements);
          }
        }
        if (allLocationElements.length) {
          anyLocationElementGeofenceFound = allLocationElements.some(
            (element) => element.properties.validationProperties.geofence === true,
          );
        }
      }
    }
    return { geofenceEnable, anyLocationElementGeofenceFound };
  };

  return (
    <>
      {(selectedField && selectedElementIndex === selectedField.position) ||
      selectedElementIndex === -1 ? (
        <>
          {!isNestedOption && (
            <div className='section-header-area'>
              <div className='d-flex gap-2 align-items-center'>
                <img src={tablePropertiesIcon} />
                <p className='theme-text-black-1 theme-size-1_2 mb-0'>
                  {t('form_edit_properties')}
                </p>
              </div>
              <p className='text-center mb-0 theme-size-0_9'>
                {t('form_choose_the_options_as_you_like')}
              </p>
            </div>
          )}
          {elementPropertiesType.map((toggleBox, index) => {
            let propertiesList = selectedField
              ? selectedField.type === 'user-field'
                ? formProperties['page' + selectedField.currentPage].elements[
                    selectedField.position
                  ].properties
                : formProperties.supervisorFields.elements[selectedField.position].properties
              : {};
            return (
              <ToggleBox key={index}>
                <ToggleBoxHeader
                  show={toggleBox.isOpen}
                  toggleBoxOpen={() => rightToggleBoxOpen(index)}
                  title={toggleBox.title}
                />
                <ToggleBoxBody
                  show={toggleBox.isOpen}
                  classes={`${
                    !isNestedOption ? 'p-3 pt-0 d-flex flex-column gap-2' : 'd-flex flex-wrap gap-3'
                  }`}
                >
                  <Fragment>
                    {selectedField ? (
                      toggleBox.nestedOption ? (
                        <FormProperties
                          selectedField={selectedField}
                          setSelectedField={setSelectedField}
                          formProperties={formProperties}
                          setFormProperties={setFormProperties}
                          toggleList={toggleBox.nestedOption}
                          isNestedOption={true}
                          dataSourcesList={dataSourcesList}
                        />
                      ) : (
                        fieldTypeAllowedProperties[toggleBox.defaultPropsKey].map(
                          (singleProperty, index) => {
                            if (
                              (singleProperty.isAllowedForAll &&
                                singleProperty.restrictedFieldTypes.indexOf(
                                  selectedField.elementType,
                                ) === -1) ||
                              singleProperty.allowedFieldTypes.indexOf(selectedField.elementType) >
                                -1
                            ) {
                              let fieldSectionProperties =
                                propertiesList[toggleBox.defaultPropsKey + 'Properties'];
                              return (
                                fieldSectionProperties && (
                                  <SingleFieldProperties
                                    key={index}
                                    selectedField={selectedField}
                                    onChangeProperties={onChangeProperties}
                                    singleProperty={singleProperty}
                                    fieldSectionProperties={fieldSectionProperties}
                                    toggleBox={toggleBox}
                                    propertiesList={propertiesList}
                                    otherDetails={formProperties.otherDetails}
                                    dataSourcesList={
                                      selectedField.elementType === 'dataSource'
                                        ? dataSourcesList
                                        : []
                                    }
                                    hasTriggers={checkMultiChoiceDisabled(singleProperty.key)}
                                    geofenceEnabledObject={checkGeofenceToggleEnabled(
                                      singleProperty.key,
                                    )}
                                  />
                                )
                              );
                            }
                          },
                        )
                      )
                    ) : (
                      ''
                    )}
                  </Fragment>
                </ToggleBoxBody>
              </ToggleBox>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
FormProperties.propTypes = {
  selectedField: PropTypes.object,
  setSelectedField: PropTypes.func.isRequired,
  formProperties: PropTypes.object.isRequired,
  setFormProperties: PropTypes.func.isRequired,
  toggleList: PropTypes.array.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  isNestedOption: PropTypes.bool.isRequired,
};
export default FormProperties;
