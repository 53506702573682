import { CallAPI } from 'actions/General';
import { cloneDeep, groupBy, sortBy } from 'lodash';
import { uploadFile, uploadImage } from 'pages/company/utils';

export const createAPIStructure = (formProperties) => {
  let errorsArray = [];
  let formPageKeys = Object.keys(formProperties).sort();
  let elements = [];
  let supervisorElements = [];
  let statusElements = [];
  let position = 1;
  let page = 1;
  let fieldUniqueIdsList = [];
  formPageKeys.map((singlePageKey) => {
    if (singlePageKey !== 'otherDetails') {
      let pageElementPosition = 0;
      let sectionNumber = 0;
      let singlePageElements = cloneDeep(formProperties[singlePageKey].elements);
      let pageErrors = [];
      singlePageElements.map((singleElement) => {
        if (['section', 'supervisor_section'].includes(singleElement.elementType)) sectionNumber++;

        // Create Single Element for API
        if (singlePageKey === 'supervisorFields') {
          const supervisorSectionList = singlePageElements
            .filter((ele) => ele.elementType === 'supervisor_section')
            .map((ele) => ({
              id: ele.elementGlobalId ?? ele.triggerFlowReferenceId,
              title: ele.properties.basicProperties.sectionTitle ?? '',
            }));
          const supervisorSectionTitleMap = Object.fromEntries(
            supervisorSectionList.map((s) => [s.id, s.title]),
          );

          if (singleElement.elementType === 'supervisor_response') {
            if (singleElement.triggers) {
              const triggersObj = createGroupedTriggersObject(singleElement.triggers);
              if (Object.keys(triggersObj).length === 0) delete singleElement.triggers;
              else singleElement.triggers = cloneDeep(triggersObj);
            }
          }

          if (singleElement.childReferenceId) delete singleElement.childReferenceId;

          let elementType = singleElement.elementType.replace('supervisor_', '');
          if (elementType === 'status')
            statusElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber,
              elementType: elementType,
              properties: singleElement.properties,
            });
          else if (
            singleElement.elementType === 'supervisor_section' &&
            singleElement.sectionWorkFlows?.length
          ) {
            singleElement.sectionWorkFlows = singleElement.sectionWorkFlows
              .filter(
                (workflow) =>
                  (workflow.sourceElementGlobalId || workflow.workFlowSourceElementReferenceId) &&
                  workflow.type !== '',
              )
              .map((workflow) => {
                if (workflow.type === 'manager_section')
                  workflow.title =
                    supervisorSectionTitleMap[
                      workflow.actionId ?? workflow.triggerFlowReferenceId
                    ] ?? '';
                return { ...workflow };
              });

            supervisorElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber,
              elementType: elementType,
              properties: singleElement.properties,
            });
          } else
            supervisorElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber,
              elementType: elementType,
              properties: singleElement.properties,
            });
        } else {
          if (singleElement.elementType === 'checkList') {
            if (singleElement.triggers) {
              console.log(singleElement.triggers);
              const triggersObj = createGroupedTriggersObject(singleElement.triggers);
              if (Object.keys(triggersObj).length === 0) delete singleElement.triggers;
              else singleElement.triggers = cloneDeep(triggersObj);
              console.log(singleElement);
            }
          }
          if (singleElement.elementType === 'dataSource') {
            let updatedBindingList = singleElement.properties.basicProperties.bindingList.filter(
              (singleBindElement) => {
                if (singleBindElement) {
                  return singleBindElement;
                }
              },
            );
            singleElement.properties.basicProperties.bindingList = updatedBindingList;
          }
          if (singleElement.childReferenceId) delete singleElement.childReferenceId;
          elements.push({
            ...singleElement,
            position,
            pageNumber: page,
            sectionNumber:
              singleElement.elementType === 'section' || singleElement.isInSection
                ? sectionNumber
                : 0,
            properties: singleElement.properties,
          });
        }
        getValidationError(
          fieldUniqueIdsList,
          cloneDeep(singleElement),
          pageElementPosition,
          pageErrors,
        );
        position++;
        pageElementPosition++;
      });
      if (pageErrors.length) {
        const errorsClassification =
          singlePageKey === 'supervisorFields'
            ? ['form_error.Supervisors fields errors']
            : ['form_error.Page (', `${page}`, 'form_error.) errors'];
        errorsArray.push({ errorsClassification, errorList: pageErrors });
      }
      if (singlePageKey.includes('page')) page++;
    }
  });

  // Check Duplicate Field Unique Key values
  const fieldUniqueIdsDuplicated =
    new Set([...fieldUniqueIdsList]).size !== fieldUniqueIdsList.length;
  if (fieldUniqueIdsDuplicated) {
    const errorsClassification = ['form_error.Duplicate IDs'];
    const errorList = [['form_error.Duplicate field IDs']];

    errorsArray.push({ errorsClassification, errorList });
  }

  const sectionErrorList = formProperties['supervisorFields'].elements.length
    ? validateSupervisorSectionStructure(formProperties['supervisorFields'].elements)
    : [];
  if (sectionErrorList.length > 0) {
    const errorsClassification = ['form_error.Supervisors fields errors'];
    errorsArray.push({ errorsClassification, errorList: sectionErrorList });
  }

  // modifying fieldid of newly created fields in watermark input and mapping object of all userField(only) elements
  const wrappingStartString = '#[';
  const wrappingEndString = ']#';
  elements.map((singleElement) => {
    if (!['page', 'section', 'image'].includes(singleElement.elementType)) {
      let watermarkText = singleElement.properties.mediaProperties.watermarkText;
      const watermarkUsedFieldsMap =
        singleElement.properties.mediaProperties.watermarkUsedFieldsMap;
      const modifiedWatermarkUsedFieldsMap = {};
      if (watermarkText) {
        // Function to escape special characters in a string for regex
        const escapeRegExp = (string) => {
          // Escape all special regex characters
          return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'); // eslint-disable-line
        };
        // Escape special characters in the wrapping strings
        const regex = new RegExp(
          `${escapeRegExp(wrappingStartString)}(.*?)${escapeRegExp(wrappingEndString)}`,
          'g',
        );
        // Use matchAll() to find all matches and map the results to remove extra characters
        const matches = [...watermarkText.matchAll(regex)].map((match) => match[1]);
        matches.forEach((usedMention) => {
          Object.values(formProperties.otherDetails.allFormElementsObj).forEach((item) => {
            if (usedMention === item.fieldId) {
              // add newly created form field(used in watermark related field) to modifiedWatermarkUsedFieldsMap object
              Object.keys(watermarkUsedFieldsMap).forEach((key) => {
                if (key === item.fieldId) {
                  modifiedWatermarkUsedFieldsMap[item.fieldName] = item.fieldId;
                }
              });
              const regexToReplace = new RegExp(`#\\[${usedMention}\\]#`, 'g');
              watermarkText = watermarkText.replace(
                regexToReplace,
                `${wrappingStartString}${item.fieldName}${wrappingEndString}`,
              );
              // modifying element watermarkText here
              singleElement.properties.mediaProperties.watermarkText = watermarkText;
            }
          });
        });
        // add old form field(used in watermark related field) to modifiedWatermarkUsedFieldsMap object
        Object.keys(watermarkUsedFieldsMap).forEach((key) => {
          if (key !== watermarkUsedFieldsMap[key]) {
            modifiedWatermarkUsedFieldsMap[key] = watermarkUsedFieldsMap[key];
          }
        });
        // modifying element watermarkUsedFieldsMap
        singleElement.properties.mediaProperties.watermarkUsedFieldsMap = {
          ...modifiedWatermarkUsedFieldsMap,
        };
      }
    }
  });

  return {
    status: !errorsArray.length,
    errors: errorsArray,
    supervisorElements,
    elements,
    statusElements,
  };
};

const createGroupedTriggersObject = (triggers) => {
  const level1Grouped = groupBy(
    triggers.filter((t) => t.types.length),
    (trigger) => trigger.matcher,
  );

  for (const level1Key in level1Grouped) {
    const level2Grouped = Object.fromEntries(
      level1Grouped[level1Key].map((l) => {
        const types = l.types.map((t) => ({
          type: t.type,
          ...((t.type === 'ask_a_question' || t.type === 'user_page') && {
            elementGlobalId: t.elementGlobalId ?? null,
            triggerFlowReferenceId: t.triggerFlowReferenceId ?? null,
          }),
        }));
        return [l.matchingValue, types];
      }),
    );

    level1Grouped[level1Key] = level2Grouped;
  }
  return level1Grouped;
};

export const createUngroupedTriggersArray = (level1Grouped) => {
  const triggerArr = [];
  const childIds = [];

  for (const level1Key in level1Grouped) {
    for (const level2Key in level1Grouped[level1Key]) {
      const types = [];
      for (const t of level1Grouped[level1Key][level2Key]) {
        if (t.type === 'ask_a_question' && t.elementGlobalId) childIds.push(t.elementGlobalId);
        types.push({
          type: t.type,
          ...(t.type === 'ask_a_question' && {
            elementGlobalId: t.elementGlobalId,
            triggerFlowReferenceId: null,
            childReferenceId: t.elementGlobalId,
          }),
          ...(t.type === 'user_page' && {
            elementGlobalId: t.elementGlobalId,
            triggerFlowReferenceId: null,
          }),
        });
      }

      console.log(types);

      triggerArr.push({ matcher: level1Key, matchingValue: level2Key, types: types });
    }
  }
  return { triggerArr, childIds };
};

export const swapFieldElements = (currentPageElements, position, action, type) => {
  const sectionType = type === 'supervisor_field' ? 'supervisor_section' : 'section';
  if (action === 'increase') {
    let moveByPosition = position + 1;
    let movingCount = 1;
    let moveByCount = 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) {
        moveByPosition = i;
        break;
      }
      movingCount++;
    }

    if (currentPageElements[moveByPosition].elementType === sectionType) {
      for (let i = moveByPosition + 1; i < currentPageElements.length; i++) {
        if (!currentPageElements[i].isInSection) break;
        moveByCount++;
      }
    } else {
      for (let i = moveByPosition + 1; i < currentPageElements.length; i++) {
        if (!currentPageElements[i].childReferenceId) break;
        moveByCount++;
      }
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(position + moveByCount, 0, ...spliced);
  } else if (action === 'decrease') {
    let moveByPosition = position - 1;
    let movingCount = 1;
    const lastIndex = type === 'supervisor_section' ? 0 : 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      movingCount++;
    }

    for (let i = position - 1; i >= lastIndex; i--) {
      if (
        // when outside of section
        (!currentPageElements[position].isInSection &&
          !currentPageElements[i].isInSection &&
          !currentPageElements[i].childReferenceId) ||
        // when inside of section
        (currentPageElements[position].isInSection && !currentPageElements[i].childReferenceId)
      ) {
        moveByPosition = i;
        break;
      }
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(moveByPosition, 0, ...spliced);
  }
};

export const swapSupervisorFieldElements = (currentPageElements, position, action, type) => {
  const sectionType = type === 'supervisor_field' ? 'supervisor_section' : 'section';
  if (action === 'increase') {
    let moveByPosition = position + 1;

    if (currentPageElements[moveByPosition].elementType === sectionType) {
      currentPageElements[position].isInSection = true;
    }

    const spliced = currentPageElements.splice(position, 1);
    currentPageElements.splice(position + 1, 0, ...spliced);
  } else if (action === 'decrease') {
    let moveByPosition = position - 1;

    if (currentPageElements[moveByPosition].elementType === sectionType) {
      let isSectionAbove = false;
      for (let i = moveByPosition - 1; i >= 0; i--) {
        if (currentPageElements[i].elementType === sectionType) {
          isSectionAbove = true;
          break;
        }
      }
      currentPageElements[position].isInSection = isSectionAbove;
    }

    const spliced = currentPageElements.splice(position, 1);
    currentPageElements.splice(moveByPosition, 0, ...spliced);
  }
};

export const swapSectionElements = (currentPageElements, position, action, type) => {
  if (action === 'increase') {
    let moveByPosition = position + 1;
    let movingCount = 1;
    let moveByCount = 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].isInSection) {
        moveByPosition = i;
        break;
      }
      movingCount++;
    }

    if (currentPageElements[moveByPosition].elementType === type) {
      for (let i = moveByPosition + 1; i < currentPageElements.length; i++) {
        if (!currentPageElements[i].isInSection) break;
        moveByCount++;
      }
    } else {
      for (let i = moveByPosition + 1; i < currentPageElements.length; i++) {
        if (!currentPageElements[i].childReferenceId) break;
        moveByCount++;
      }
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(position + moveByCount, 0, ...spliced);
  } else if (action === 'decrease') {
    let moveByPosition = position - 1;
    let movingCount = 1;
    const lastIndex = type === 'supervisor_section' ? 0 : 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].isInSection) break;
      movingCount++;
    }

    for (let i = position - 1; i >= lastIndex; i--) {
      if (!currentPageElements[i].childReferenceId && !currentPageElements[i].isInSection) {
        moveByPosition = i;
        break;
      }
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(moveByPosition, 0, ...spliced);
  }
};

export const swapSupervisorSectionElements = (currentPageElements, position, action, type) => {
  if (action === 'increase') {
    let moveByPosition = position + 1;

    if (currentPageElements[moveByPosition].elementType !== type) {
      let isSectionAbove = false;
      for (let i = position - 1; i >= 0; i--) {
        if (currentPageElements[i].elementType === type) {
          isSectionAbove = true;
          break;
        }
      }
      currentPageElements[moveByPosition].isInSection = isSectionAbove;
    }

    const spliced = currentPageElements.splice(position, 1);
    currentPageElements.splice(position + 1, 0, ...spliced);
  } else if (action === 'decrease') {
    let moveByPosition = position - 1;

    if (currentPageElements[moveByPosition].elementType !== type) {
      currentPageElements[moveByPosition].isInSection = true;
    }

    const spliced = currentPageElements.splice(position, 1);
    currentPageElements.splice(moveByPosition, 0, ...spliced);
  }
};

export const removeFieldWithChildElements = (
  currentPageElements,
  position,
  pageMapObject,
  currentPageId,
) => {
  let deletionCount = 1;
  const deletedFieldId =
    currentPageElements[position].elementGlobalId ??
    currentPageElements[position].uniqueIdToReplace ??
    null;
  for (let i = position + 1; i < currentPageElements.length; i++) {
    if (!currentPageElements[i] || !currentPageElements[i].childReferenceId) break;
    deletionCount++;
  }
  const removedElements = currentPageElements.splice(position, deletionCount);
  if (currentPageId) {
    const removedTriggerPageIds = getElementsPageTriggersTypes(removedElements);
    removeMappingFromPageMapObject(pageMapObject, currentPageId, removedTriggerPageIds);
  }
  return deletedFieldId;
};

export const removeSectionWithChildElements = (
  currentPageElements,
  position,
  removedElements,
  pageMapObject,
  currentPageId,
) => {
  let deletionCount = 1;
  for (let i = position + 1; i < currentPageElements.length; i++) {
    if (
      !currentPageElements[i] ||
      !currentPageElements[i].isInSection ||
      currentPageElements[i].elementType === 'section' ||
      currentPageElements[i].elementType === 'supervisor_section'
    )
      break;
    removedElements.push(currentPageElements[i]);
    deletionCount++;
  }
  currentPageElements.splice(position, deletionCount);
  if (currentPageId) {
    const removedTriggerPageIds = getElementsPageTriggersTypes(removedElements);
    removeMappingFromPageMapObject(pageMapObject, currentPageId, removedTriggerPageIds);
  }
};

export const swapPageElements = (properties, detail, action) => {
  const replacedPageNumber = detail.pageNumber + (action === 'increase' ? 1 : -1);
  const replacingPageElements = properties['page' + detail.pageNumber].elements;

  properties['page' + detail.pageNumber].elements =
    properties['page' + replacedPageNumber].elements;
  properties['page' + replacedPageNumber].elements = replacingPageElements;
};

export const removePageElementWithChildElements = (
  properties,
  detail,
  currentPage,
  setCurrentPage,
  setTotalPages,
  removedElements,
) => {
  removedElements = properties['page' + detail.pageNumber].elements;

  const deletedPageElement =
    properties['page' + detail.pageNumber].elements.find((e) => e.elementType === 'page') ?? null;
  const deletedPageId =
    deletedPageElement?.elementGlobalId ?? deletedPageElement?.triggerFlowReferenceId;

  if (deletedPageId) {
    const removedTriggerPageIds = getElementsPageTriggersTypes(removedElements);
    console.log({ removedTriggerPageIds });

    removeMappingFromAllPageMapObject(
      properties.otherDetails.gotoPageTriggerMapObj,
      deletedPageId,
      // removedTriggerPageIds,
    );
    for (let index = 1; index <= detail.totalPages; index++) {
      if (index !== detail.pageNumber) {
        console.log('here', detail.pageNumber, index);
        deleteTriggerTypesOfDeletedPage(properties['page' + index].elements, deletedPageId);
      }
    }
  }

  if (detail.pageNumber === detail.totalPages) {
    delete properties['page' + detail.pageNumber];
  } else {
    for (let index = detail.pageNumber; index < detail.totalPages; index++) {
      properties['page' + index].elements = properties['page' + (index + 1)].elements;
    }
    delete properties['page' + detail.totalPages];
  }

  if (detail.pageNumber <= currentPage && currentPage !== 1) setCurrentPage(currentPage - 1);
  setTotalPages((totalPages) => totalPages - 1);

  return removedElements;
};

const getElementsPageTriggersTypes = (elementsList) => {
  const pageIdsToRemove = [];
  for (let i = 0; i < elementsList.length; i++) {
    const element = elementsList[i];
    if (element.triggers?.length) {
      for (const trigger of element.triggers) {
        for (let j = 0; j < trigger.types.length; j++) {
          if (trigger.types[j].type === 'user_page') {
            pageIdsToRemove.push(
              trigger.types[j].elementGlobalId ?? trigger.types[j].triggerFlowReferenceId,
            );
          }
        }
      }
    }
  }
  return pageIdsToRemove;
};

export const removeSingleTriggerType = (elementsList, elementIndex, triggerIndex, typeIndex) => {
  console.log(elementsList, elementIndex, triggerIndex, typeIndex);

  const childElementIndicesToDelete = [];
  const childElement = elementsList[elementIndex].triggers[triggerIndex].types[typeIndex];
  if (childElement.type === 'ask_a_question') {
    const childElementIndex = elementsList.findIndex(
      (e) => e.childReferenceId === childElement.childReferenceId,
    );
    console.log({ childElementIndex });

    // also delete the child element from main list
    if (childElementIndex > -1) {
      childElementIndicesToDelete.push(childElementIndex);

      //Now finding if that child element has trigger elements, we need to delete those as well
      const childElementTriggers = elementsList[childElementIndex].triggers;
      if (childElementTriggers?.length) {
        for (const trigger of childElementTriggers) {
          for (let i = 0; i < trigger.types.length; i++) {
            if (trigger.types[i].type === 'ask_a_question') {
              const nextChildIndex = elementsList.findIndex(
                (e) => e.childReferenceId === trigger.types[i].childReferenceId,
              );
              if (nextChildIndex > -1) childElementIndicesToDelete.push(nextChildIndex);
            }
          }
        }
      }
    }
  }
  console.log({ typeIndex });

  console.log(childElementIndicesToDelete);
  // Reverse sort for removing elements from downwards
  childElementIndicesToDelete.sort((a, b) => b - a);
  childElementIndicesToDelete.forEach((indexToDelete) => {
    elementsList.splice(indexToDelete, 1);
  });
  elementsList[elementIndex].triggers[triggerIndex].types.splice(typeIndex, 1);
};

export const removeMappingFromPageMapObject = (pageMapObject, currentPageId, pageIds) => {
  // remove the mapping from pageMapObject
  for (let i = 0; i < pageIds.length; i++) {
    if (pageMapObject[currentPageId]) {
      const index = pageMapObject[currentPageId].indexOf(pageIds[i]);
      if (index !== -1) pageMapObject[currentPageId].splice(index, 1);
    }
  }
  if (pageMapObject[currentPageId]?.length === 0) delete pageMapObject[currentPageId];
};

const removeMappingFromAllPageMapObject = (pageMapObject, deletedPageId) => {
  // remove the mapping from pageMapObject for all pages
  delete pageMapObject[deletedPageId];
  Object.entries(pageMapObject).forEach(([key, value]) => {
    let index = value.indexOf(deletedPageId);
    while (index !== -1) {
      value.splice(index, 1);
      index = value.indexOf(deletedPageId);
    }
    if (value.length === 0) delete pageMapObject[key];
  });
};

const deleteTriggerTypesOfDeletedPage = (elementsList, deletedPageId) => {
  for (let i = 0; i < elementsList.length; i++) {
    const element = elementsList[i];
    if (element.triggers?.length) {
      for (let j = element.triggers.length - 1; j >= 0; j--) {
        const trigger = element.triggers[j];
        for (let k = trigger.types.length - 1; k >= 0; k--) {
          if (trigger.types[k].type === 'user_page') {
            if (
              trigger.types[k].elementGlobalId === deletedPageId ||
              trigger.types[k].triggerFlowReferenceId === deletedPageId
            )
              trigger.types.splice(k, 1);
          }
        }
        if (!trigger.types.length) element.triggers.splice(j, 1);
      }
    }
  }
};

export const moveElementToPosition = (
  currentPageElements,
  movedPosition,
  moveToPosition,
  isMovedInsideSection,
) => {
  let movingCount = 1;
  let moveByCount = 1;
  const isMovedElementInSection = currentPageElements[movedPosition].isInSection;

  console.log(currentPageElements, movedPosition, moveToPosition);

  if (movedPosition < moveToPosition) {
    for (let i = movedPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      movingCount++;
    }
    console.log({ movedPosition, movingCount });

    for (let i = moveToPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      moveByCount++;
    }

    if (
      (!isMovedElementInSection && isMovedInsideSection) ||
      (isMovedElementInSection && !isMovedInsideSection)
    ) {
      for (let i = movedPosition; i < movedPosition + movingCount; i++)
        currentPageElements[i].isInSection = isMovedInsideSection;
    }

    const spliced = currentPageElements.splice(movedPosition, movingCount);
    console.log(spliced, moveToPosition + moveByCount - movingCount, currentPageElements);
    currentPageElements.splice(moveToPosition + moveByCount - movingCount, 0, ...spliced);
  } else if (movedPosition > moveToPosition) {
    for (let i = movedPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      movingCount++;
    }
    console.log({ movedPosition, movingCount });

    for (let i = moveToPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      moveByCount++;
    }

    if (
      (!isMovedElementInSection && isMovedInsideSection) ||
      (isMovedElementInSection && !isMovedInsideSection)
    ) {
      for (let i = movedPosition; i < movedPosition + movingCount; i++)
        currentPageElements[i].isInSection = isMovedInsideSection;
    }

    const spliced = currentPageElements.splice(movedPosition, movingCount);
    console.log(spliced, moveToPosition + moveByCount - movingCount, currentPageElements);
    currentPageElements.splice(moveToPosition + moveByCount, 0, ...spliced);
  } else if (movedPosition === moveToPosition) {
    for (let i = movedPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      movingCount++;
    }
    console.log({ movedPosition, movingCount });
    console.log({ isMovedElementInSection, isMovedInsideSection });

    if (
      (!isMovedElementInSection && isMovedInsideSection) ||
      (isMovedElementInSection && !isMovedInsideSection)
    ) {
      for (let i = movedPosition; i < movedPosition + movingCount; i++)
        currentPageElements[i].isInSection = isMovedInsideSection;
    } else console.log('No move');
  }
};

export const jsonStringifyAllElements = (APIData) => {
  for (let index = 0; index < APIData.elements.length; index++) {
    const element = APIData.elements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.supervisorElements.length; index++) {
    const element = APIData.supervisorElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.statusElements.length; index++) {
    const element = APIData.statusElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  return APIData;
};
export const removeUnbindElementUniqueIds = (elements, formProperties) => {
  const onChangeTriggerFieldIds = [];
  cloneDeep(elements).forEach((el) => {
    if (el.elementType === 'externalApi') {
      const basicProps = JSON.parse(el.properties).basicProperties;
      if (basicProps.onChangeTriggerFieldId) {
        onChangeTriggerFieldIds.push(basicProps.onChangeTriggerFieldId);
      }
    }
  });

  // saving all elements watermarkMapUsedFields in one array
  const allWatermarkMapUsedField = [];
  elements.map((singleElement) => {
    const elemMediaProperites = JSON.parse(singleElement.properties).mediaProperties;
    const singleElementwatermarkUsedFieldsMapArray = elemMediaProperites
      ? Object.values(elemMediaProperites.watermarkUsedFieldsMap)
      : [];
    if (elemMediaProperites && singleElementwatermarkUsedFieldsMapArray.length > 0) {
      allWatermarkMapUsedField.push(...singleElementwatermarkUsedFieldsMapArray);
    }
  });

  elements.map((singleElement) => {
    // we need to remove unique id to replace from elements which are not present in binding list and external api onChangeTriggerField and WatermarkMapUsedField
    if (
      singleElement.uniqueIdToReplace &&
      !formProperties.otherDetails.bindingElementsObj[singleElement.uniqueIdToReplace] &&
      !onChangeTriggerFieldIds.includes(singleElement.uniqueIdToReplace) &&
      !allWatermarkMapUsedField.includes(singleElement.uniqueIdToReplace)
    ) {
      delete singleElement.uniqueIdToReplace;
    }
  });
  return elements;
};

const getValidationError = (fieldUniqueIdsList, singleElement, pageElementPosition, pageErrors) => {
  // Check Title and Field Unique Key That these must be added except Image Element
  if (singleElement.elementType !== 'image') {
    let key =
      singleElement.elementType === 'page'
        ? 'pageTitle'
        : singleElement.elementType === 'section' ||
          singleElement.elementType === 'supervisor_section'
        ? 'sectionTitle'
        : 'fieldTitle';
    // Check Title is not empty
    if (singleElement.properties.basicProperties[key] === '') {
      if (singleElement.elementType === 'page')
        pageErrors.push(['form_error.Page has missing title']);
      else
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing title',
        ]);
    }
    // Check field Unique Id Exist, Add in array to check unique
    if (
      singleElement.properties.otherProperties &&
      singleElement.properties.otherProperties.fieldUniqueId &&
      singleElement.properties.otherProperties.fieldUniqueId !== ''
    ) {
      fieldUniqueIdsList.push(singleElement.properties.otherProperties.fieldUniqueId);
    }

    // Check Empty Field Unique Key error
    if (
      singleElement.elementType !== 'page' &&
      singleElement.elementType !== 'section' &&
      singleElement.elementType !== 'supervisor_section' &&
      singleElement.properties.otherProperties.fieldUniqueId === ''
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing field unique id',
      ]);
    }

    // Check Data Source Related Selected keys
    if (singleElement.elementType === 'dataSource') {
      if (singleElement.properties.basicProperties.dataSource === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source options',
        ]);
      }
      if (singleElement.properties.basicProperties.displayColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source display column',
        ]);
      }
      if (singleElement.properties.basicProperties.valueColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source value column',
        ]);
      }
    }

    // CheckList, Status Related Mandatory Options
    if (
      ['checkList', 'status', 'supervisor_response'].includes(singleElement.elementType) &&
      singleElement.properties.basicProperties.optionList.length < 2
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing answer choices',
      ]);
    }
  } else if (
    !singleElement.properties.layoutProperties.fieldImage?.id &&
    !singleElement.properties.layoutProperties.fieldImageFile
  ) {
    pageErrors.push([
      'form_error.Element (',
      `${pageElementPosition}`,
      'form_error.) has missing image',
    ]);
  }

  return pageErrors;
};

const validateSupervisorSectionStructure = (elements) => {
  const errorList = [];
  // supervisorFields must have a section on top
  if (elements[0]?.elementType !== 'supervisor_section') {
    errorList.push(['form_error.Supervisor fields must have a section on top']);
  }
  let isOutsideSectionErrorAdded = false;
  let isEmptySectionErrorAdded = false;
  elements.forEach((el, i) => {
    // any supervisor field can't exist outside of section.
    if (el.elementType !== 'supervisor_section' && !el.isInSection && !isOutsideSectionErrorAdded) {
      errorList.push(['form_error.Supervisor fields must be placed within a section']);
      isOutsideSectionErrorAdded = true;
    }
    // any manager section can't be empty
    if (
      el.elementType === 'supervisor_section' &&
      (!elements[i + 1] || elements[i + 1]?.elementType === 'supervisor_section') &&
      !isEmptySectionErrorAdded
    ) {
      errorList.push(['form_error.Supervisor every manager section must have a field within it']);
      isEmptySectionErrorAdded = true;
    }
  });
  return errorList;
};

export const uploadImageFieldsMedia = async (formElementsList, formDetails) => {
  const imageElements = formElementsList.filter((element) => element.elementType === 'image');

  const formId = formDetails?._id ?? '';

  const formVersionId = (formDetails?.versions ?? []).length
    ? sortBy(formDetails.versions, (e) => parseInt(e.version))[formDetails.versions.length - 1]._id
    : '';

  for (const imageElement of imageElements) {
    let imageProps = imageElement.properties;
    const fieldImageFile = imageProps.layoutProperties.fieldImageFile ?? null;
    const fieldImage = imageProps.layoutProperties.fieldImage ?? null;

    if (fieldImageFile) {
      if (fieldImage) await deleteFieldImageIfExist(fieldImage);
      const uploadResponse = await uploadSingleFieldImage(fieldImageFile, formId, formVersionId);
      delete imageProps.layoutProperties.fieldImageFile;

      imageProps.layoutProperties = {
        ...imageProps.layoutProperties,
        ...(uploadResponse ? { fieldImage: uploadResponse } : undefined),
      };
      imageElement.properties = imageProps;
    }
  }
};

const deleteFieldImageIfExist = async (fieldImage) => {
  if (fieldImage.id) {
    await CallAPI(
      'DELETE_MEDIA',
      {
        folderType: 'forms',
        mediaType: 'image',
        mediaId: fieldImage.id,
        imageName: fieldImage.imageName,
        formId: fieldImage.formId,
        versionId: fieldImage.versionId,
      },
      null,
      null,
      null,
      null,
    );
  }
};

const uploadSingleFieldImage = async (file, formId, formVersionId) => {
  const imageData = { imageName: `${Date.now()}.jpg`, formId, versionId: formVersionId };
  const response = await CallAPI(
    'GET_FORM_MEDIA_UPLOAD_URL',
    { ...imageData, mediaType: 'image' },
    null,
    null,
  );
  if (response.status) {
    await uploadImage(file, response.data, null);
    return { ...imageData, id: response.data.id };
  }
  return null;
};

export const uploadSingleFile = async (file, formId, formVersionId, mediaType) => {
  const fileMetaData = {
    imageName: `${Date.now()}${file.name.slice(file.name.lastIndexOf('.'))}`,
    formId,
    versionId: formVersionId,
  };
  const response = await CallAPI(
    'GET_FORM_MEDIA_UPLOAD_URL',
    { ...fileMetaData, mediaType: mediaType },
    null,
    null,
  );
  if (response.status) {
    await uploadFile(file, response.data, null);
    return { ...fileMetaData, id: response.data.id }; // Return the file data with the ID from the response
  }
  return null; // Return null if upload fails
};

export const deleteSingleFile = async (fieldImage, folderType = 'forms') => {
  await CallAPI(
    'DELETE_MEDIA',
    {
      folderType,
      mediaType: fieldImage.mediaType,
      mediaId: fieldImage.id,
      imageName: fieldImage.imageName,
      formId: fieldImage.formId,
      versionId: fieldImage.versionId,
    },
    null,
    null,
    null,
    null,
  );
};

export const getImageUrl = async (mediaId) => {
  let result = await CallAPI('GET_FORM_MEDIA_REDIRECT_URL', null, mediaId, null, null);
  if (result.status) {
    return result.data?.url ?? null;
  } else return null;
};

export const createStructureForExportJson = (elements) => {
  elements.map((singleElement) => {
    delete singleElement.company;
    delete singleElement.elementGlobalId;
    delete singleElement._id;
    delete singleElement.uniqueIdToReplace;
    delete singleElement.triggers;
    delete singleElement.sectionWorkFlows;

    if (singleElement.elementType === 'dataSource') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        dataSource: '',
        displayColumn: '',
        valueColumn: '',
        bindingList: [],
      };
    } else if (singleElement.elementType === 'externalApi') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        apiMethod: 'post',
        apiParamType: 'url',
        apiParamValue: '',
        ...(singleElement.properties.basicProperties.statusFieldBinding
          ? { statusFieldBinding: '' }
          : {}), // only for older forms
        apiUrl: '',
        bindingList: [],
        auth: {
          authType: 'api_auth',
          authValue: '',
          authApiUrl: '',
          authApiMethod: 'post',
          responseAuthKeyName: '',
          parameters: { client_name: '', client_id: '', client_secret: '' },
        },
      };
    } else if (singleElement.elementType === 'image') {
      singleElement.properties.layoutProperties = {
        ...singleElement.properties.layoutProperties,
        fieldImageFile: null,
        fieldImage: null,
      };
    }
  });
  return elements;
};
