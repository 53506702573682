import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { isRTL } from 'config/config';

const TableComponent = ({
  header,
  filterEnabled,
  data,
  loader,
  props,
  isActionsEnabled,
  isSelectionEnabled,
  selectionKey,
  onSelection,
  selectedRows,
  debounceFunc,
  filteringData,
  setFilteringData,
  filterData
}) => {
  let {
    mediaEnabled,
  } = { ...props };
  const [sortedTableHeader, setSortedTableHeader] = useState([]);
  const htmlDirection = isRTL();

  const [initialColsWidthMap, setInitialColsWidthMap] = useState({
   'sr': {
      width: 65
    },
    'userName': {
      width: 190
    },
    'submitted': {
      width: 150
    },
    // 'Form Status': {
    //   width: 120
    // },
    'webView': {
      width: 105
    },
    // 'Comments': {
    //   width: 120
    // },
    'time': {
      width: 130
    },
    'referenceNumber': {
      width: 175
    },
    'viewLogs': {
      width: 120
    },
  });

  const widthOfCheckboxCol = 50;

  useEffect(()=>{
    let widthOfPreviousCols = widthOfCheckboxCol;
    Object.keys(initialColsWidthMap).forEach((key)=>{
      const widthCurrentCol = initialColsWidthMap[key].width;
      initialColsWidthMap[key].left = widthOfPreviousCols;
      widthOfPreviousCols = widthOfPreviousCols + widthCurrentCol;
    });
    setInitialColsWidthMap((prevState)=>{return {...prevState}});
  }, [header]);

  const tableSortOrder = ["user", "basicFields", "supervisorFields"];

  // sorting the header data by tabletype like first show user, then basicFields and in last supervisorFields
  useEffect(() => {
    if(header.length>0){
      const sortedHeader = header.sort((a, b)=>{
        const aOrder = tableSortOrder.indexOf(a?.tableType);
        const bOrder = tableSortOrder.indexOf(b?.tableType);
        return aOrder - bOrder;
      });
      setSortedTableHeader(()=>{return [...sortedHeader]});
    }
  }, [header]);

  // // add dummy column data to table body data
  // useEffect(()=>{
  //   if(data?.length>0){
  //     data.forEach((value, index)=>{
  //       value.formStatus = `status ${index+1}`;
  //       value.webView = `webview ${index+1}`;
  //       value.comments = `comments ${index+1}`;
  //     });
  //   }
  // },[data]);


  const findLastIndex = (arr, property, value) => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i][property] === value) {
        return i;
      }
    }
    return -1; // Return -1 if not found
  };

  // apply side border to each type of partition in table header
  const applyTablePartitionSideBorder = (fieldHeader, fieldIndex)=>{
    const tableType = fieldHeader?.tableType;
    const indexOfLastCellOfPartition = findLastIndex(sortedTableHeader, "tableType", tableType);
    if(
      indexOfLastCellOfPartition === fieldIndex
      ){
        return htmlDirection ? 'cell-border-left' : 'cell-border-right'
    }
    return '';
  }

  // apply side border to each section in "basicField" except the last one, in table header
  const applySecSideBorder = (fieldHeader, fieldIndex)=>{
    const title = fieldHeader?.sectionTitle;
    const lastIndexOfTitle = findLastIndex(sortedTableHeader, "sectionTitle", title);
    const lastIndexOfBasicField = findLastIndex(sortedTableHeader, "tableType", "basicFields");
    if(
      lastIndexOfTitle===fieldIndex && 
      fieldHeader?.tableType==="basicFields" &&
      fieldIndex!==lastIndexOfBasicField
      ){
        return htmlDirection ? 'cell-border-left' : 'cell-border-right'
    }
    return '';
  }


  function mergeColumns(arr) {
    const mergedArray = [];
    for (let i = 0; i < arr.length; i++) {
      const currentItem = arr[i];
      // If it's the last item or the next item has a different title, push it directly
      if (i === arr.length - 1 || currentItem.title !== arr[i + 1].title || currentItem.title === "") {
        mergedArray.push(currentItem);
      } else {
        let spanCount = currentItem.spanLength;
        // Merge consecutive elements with the same title
        while (i < arr.length - 1 && currentItem.title === arr[i + 1].title) {
          spanCount += arr[i + 1].spanLength;
          i++;
        }
        mergedArray.push({ title: currentItem.title, spanLength: spanCount });
      }
    }
    return mergedArray;
  }

  const [headerSectionTitles, setHeaderSectionTitles] = useState([]);
  useEffect(()=>{
    const sectionNameArray = sortedTableHeader?.map((value)=>{
      return {
        title: value?.sectionTitle?.length>0 ? value.sectionTitle : "",
        spanLength: 1
      }
    })
    if(sectionNameArray?.length>0){
      const mergedArray = mergeColumns(sectionNameArray);
      if(mergedArray?.length>0) setHeaderSectionTitles(mergedArray);
    }
  }, [sortedTableHeader]);

  const anySectionExists = (arr) => {
    const hasTitle = arr.some(obj => obj.title.length > 0);
    if (hasTitle) return true;
    return false;
  };

  return (
    <div className='d-flex'>
      <div
        className = "overflow-auto position-relative"
      >
        <div
          className = "table-responsive maintain-height maintain-max-height submission-table" 
        >
          <table
            className = {`table-submission ${headerSectionTitles?.length>0 && anySectionExists(headerSectionTitles) ? 'adjust-table' : ''}`}
            style={{direction: htmlDirection ? 'rtl' : 'ltr'}}
            >
            <TableHeader
              filterEnabled={filterEnabled}
              mediaEnabled={mediaEnabled ?? false}
              tableFields={sortedTableHeader}
              onSelection={onSelection}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectionValue={
                selectedRows && selectedRows.length > 0
                  ? selectedRows.length === data.length
                  : false
              }
              debounceFunc={debounceFunc}
              filteringData={filteringData}
              setFilteringData={setFilteringData}
              setInitialColsWidthMap={setInitialColsWidthMap}
              headerSectionTitles={headerSectionTitles}
              anySectionExists={anySectionExists}
              initialColsWidthMap={initialColsWidthMap}
              widthOfCheckboxCol={widthOfCheckboxCol}
              applyTablePartitionSideBorder={applyTablePartitionSideBorder}
              applySecSideBorder={applySecSideBorder}
              htmlDirection={htmlDirection}
            />
            <TableBody
              onSelection={onSelection}
              data={data}
              tableFields={sortedTableHeader}
              loader={loader}
              props={props}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectedRows={selectedRows}
              initialColsWidthMap={initialColsWidthMap}
              widthOfCheckboxCol={widthOfCheckboxCol}
              applyTablePartitionSideBorder={applyTablePartitionSideBorder}
              applySecSideBorder={applySecSideBorder}
              htmlDirection={htmlDirection}
              filterData={filterData}
              classes={{adjustTableBody: headerSectionTitles?.length>0 && anySectionExists(headerSectionTitles) ? 'adjust-tablebody' : ''}}
            />
          </table>
        </div>
      </div>
    </div>
  );
};
TableComponent.propTypes = {
  selectedRows: PropTypes.array,
  header: PropTypes.array.isRequired,
  filterEnabled: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  props: PropTypes.object.isRequired,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  onSelection: PropTypes.func,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  filterData: PropTypes.object
};
export default TableComponent;