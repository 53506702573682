import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import chevronIcon from 'assets/images/icons/chevron.svg';
import crossGreyIcon from 'assets/images/icons/cross-grey.svg';
import chevronGreyIcon from 'assets/images/icons/chevron-grey.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import sectionIcon from 'assets/images/icons/section.svg';
import infinityIcon from 'assets/images/icons/infinity.svg';
import arrowIcon from 'assets/images/icons/arrow-narrow-up-right.svg';
import userIcon from 'assets/images/icons/users-green.svg';
import NestedSelect from 'components/form-components/NestedSelect';
import { isValues } from '../utils';

const SectionWorkFlowContainer = ({
  supervisorElements,
  sectionElementIndex,
  addWorkflowInSection,
  handleSectionWorkflowChange,
}) => {
  return (
    <Fragment>
      <SectionWorkFlows
        supervisorElements={supervisorElements}
        sectionElementIndex={sectionElementIndex}
        addWorkflowInSection={addWorkflowInSection}
        handleSectionWorkflowChange={handleSectionWorkflowChange}
      />
    </Fragment>
  );
};

SectionWorkFlowContainer.propTypes = {
  supervisorElements: PropTypes.array.isRequired,
  sectionElementIndex: PropTypes.number.isRequired,
  addWorkflowInSection: PropTypes.func.isRequired,
  handleSectionWorkflowChange: PropTypes.func.isRequired,
};

const SectionWorkFlows = ({
  supervisorElements,
  sectionElementIndex,
  addWorkflowInSection,
  handleSectionWorkflowChange,
}) => {
  const { t } = useTranslation();

  const sectionResponseElements = [];
  const sectionList = supervisorElements
    .filter((ele, i) => ele.elementType === 'supervisor_section' && i !== sectionElementIndex)
    .map((ele) => ({
      triggerFlowReferenceId: ele.triggerFlowReferenceId ?? null,
      elementGlobalId: ele.elementGlobalId ?? null,
      title:
        ele.properties.basicProperties?.sectionTitle !== ''
          ? ele.properties.basicProperties.sectionTitle
          : 'Untitled Section',
    }));
  const sectionWorkFlows = supervisorElements[sectionElementIndex]?.sectionWorkFlows ?? [];

  for (let index = sectionElementIndex + 1; supervisorElements.length; index++) {
    if (
      !supervisorElements[index] ||
      supervisorElements[index].elementType === 'supervisor_section'
    )
      break;

    if (supervisorElements[index].elementType === 'supervisor_response')
      sectionResponseElements.push(supervisorElements[index]);
  }

  const actionList = sectionResponseElements.map((ele) => ({
    value: ele.elementGlobalId ?? ele.workFlowSourceElementReferenceId,
    displayValue:
      ele.properties.basicProperties?.fieldTitle !== ''
        ? ele.properties.basicProperties.fieldTitle
        : 'Untitled Action',
    actionOptions: ele.properties.basicProperties?.optionList ?? [],
  }));

  const optionsMap = Object.fromEntries(
    actionList.map((action) => [
      action.value,
      action.actionOptions.map((option) => ({
        value: option.value,
        displayValue: option.responseText,
      })),
    ]),
  );

  const workflowTypes = [
    {
      value: 'go_to',
      displayValue: 'Go to',
      nestedOptions: [
        { value: 'app_user', displayValue: 'App User' },
        // { value: 'manager_section', displayValue: 'Section Name' },
        ...sectionList.map((s) => ({
          value: `manager_section|${s.elementGlobalId ?? s.triggerFlowReferenceId}`,
          displayValue: s.title,
        })),
        { value: 'end_of_form', displayValue: 'End of Form' },
      ],
    },
  ];

  const handleSectionWorkflowActionChange = (sectionElementIndex, workflowIndex, name, value) => {
    const foundAction = sectionResponseElements.find(
      (ele) => ele.elementGlobalId === value || ele.workFlowSourceElementReferenceId === value,
    );
    if (foundAction) {
      handleSectionWorkflowChange(sectionElementIndex, workflowIndex, null, value, {
        sourceElementGlobalId: foundAction.elementGlobalId ?? null,
        workFlowSourceElementReferenceId: foundAction.workFlowSourceElementReferenceId ?? null,
        // resetting all other values on action change
        sourceValue: '',
        type: '',
        triggerFlowReferenceId: null,
        title: '',
        actionId: null,
      });
    }
  };

  const handleSectionWorkflowTypeChange = (sectionElementIndex, workflowIndex, name, value) => {
    console.log('handleSectionWorkflowTypeChange', name, value);

    if (name === 'go_to') {
      if (value === 'app_user' || value === 'end_of_form')
        handleSectionWorkflowChange(sectionElementIndex, workflowIndex, 'type', value, {
          triggerFlowReferenceId: null,
          title: '',
          actionId: null,
        });
      else if (value.includes('manager_section')) {
        const splitValue = value.split('|');
        const foundSection = sectionList.find(
          (s) => s.elementGlobalId === splitValue[1] || s.triggerFlowReferenceId === splitValue[1],
        );
        if (foundSection)
          handleSectionWorkflowChange(sectionElementIndex, workflowIndex, 'type', splitValue[0], {
            triggerFlowReferenceId: foundSection.triggerFlowReferenceId ?? null,
            title: foundSection.title,
            actionId: foundSection.elementGlobalId ?? null,
          });
      }
    } else {
      handleSectionWorkflowChange(sectionElementIndex, workflowIndex, name, value, {
        triggerFlowReferenceId: null,
        title: '',
        actionId: null,
      });
    }
  };

  const getDisplayValue = (list, checkValue, defaultValue = '') => {
    return list.find((o) => o.value === checkValue)?.displayValue ?? defaultValue;
  };

  //   useEffect(() => {
  //     const valuesMap = Object.fromEntries(
  //       supervisorElements
  //         .filter((element) => element.elementType === 'supervisor_response')
  //         .map((element) => [
  //           element.workFlowSourceElementReferenceId,
  //           (element.properties.basicProperties?.optionList ?? []).map((option) => ({
  //             value: option.value,
  //             displayValue: option.responseText,
  //           })),
  //         ]),
  //     );
  //     setOptionsMap(valuesMap);
  //   }, []);

  return (
    <div className='theme-background-green-4 p-3'>
      {sectionWorkFlows.map((workflow, workflowIndex) => (
        <div key={workflowIndex} className='d-flex gap-2 mx-1 mb-3'>
          <div className={`border-green-1-h-2 border-end-0 px-1`}></div>
          <div className='flex-grow-1 p-2'>
            <div className='d-flex gap-2 align-items-center'>
              <label className='theme-size-0_9'>IF</label>
              <NestedSelect
                options={actionList}
                handleChange={(_, value) =>
                  handleSectionWorkflowActionChange(sectionElementIndex, workflowIndex, null, value)
                }
                name='sourceElementReferenceId' // only for dummy, not used
                toggleClasses='px-2 py-1 border-grey-1-h-1'
                toggleComponent={
                  <>
                    <label className='theme-size-0_9 cursor-pointer'>
                      {t(
                        getDisplayValue(
                          actionList,
                          workflow.sourceElementGlobalId ??
                            workflow.workFlowSourceElementReferenceId,
                          'select_action',
                        ),
                      )}
                    </label>
                    <img src={chevronGreyIcon} className='rotate-180' />
                  </>
                }
              />

              <NestedSelect
                options={isValues}
                handleChange={(name, value) =>
                  handleSectionWorkflowChange(sectionElementIndex, workflowIndex, name, value)
                }
                name='operator'
                toggleClasses='px-1'
                toggleComponent={
                  <>
                    <label className='theme-size-0_9 cursor-pointer'>
                      {t(getDisplayValue(isValues, workflow.operator))}
                    </label>
                    <img src={chevronGreyIcon} className='rotate-180' />
                  </>
                }
              />
              <NestedSelect
                options={
                  optionsMap[
                    workflow.sourceElementGlobalId ?? workflow.workFlowSourceElementReferenceId
                  ] ?? []
                }
                handleChange={(name, value) =>
                  handleSectionWorkflowChange(sectionElementIndex, workflowIndex, name, value)
                }
                name='sourceValue'
                toggleClasses='px-2 py-1 border-grey-1-h-1'
                toggleComponent={
                  <>
                    <label className='theme-size-0_9 cursor-pointer'>
                      {t(
                        getDisplayValue(
                          optionsMap[
                            workflow.sourceElementGlobalId ??
                              workflow.workFlowSourceElementReferenceId
                          ] ?? [],
                          workflow.sourceValue,
                          'select_value',
                        ),
                      )}
                    </label>
                    <img src={chevronGreyIcon} className='rotate-180' />
                  </>
                }
              />

              {workflow.sourceValue && (
                <Fragment>
                  <label className='mx-2 theme-size-0_9'>THEN</label>
                  {workflow.type !== '' ? (
                    <div className='d-flex gap-2 align-items-center theme-background-green-4 p-2 rounded-sm'>
                      <WorkflowTypeLabel workflow={workflow} />
                      <img
                        className='cursor-pointer'
                        onClick={() =>
                          handleSectionWorkflowTypeChange(
                            sectionElementIndex,
                            workflowIndex,
                            'type',
                            '',
                          )
                        }
                        src={crossGreyIcon}
                      />
                    </div>
                  ) : (
                    <NestedSelect
                      options={workflowTypes}
                      handleChange={(name, value) =>
                        handleSectionWorkflowTypeChange(
                          sectionElementIndex,
                          workflowIndex,
                          name,
                          value,
                        )
                      }
                      name='type'
                      toggleClasses='px-1 py-2'
                      toggleComponent={
                        <>
                          <label className='theme-size-0_9 cursor-pointer theme-text-green-1'>
                            {t('+ trigger')}
                          </label>
                          <img src={chevronIcon} className='rotate-180' />
                        </>
                      }
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      ))}
      <div
        className='d-flex gap-1 align-items-center border-green-1-h-2 rounded-sm p-1 width-max-content'
        onClick={() => addWorkflowInSection(sectionElementIndex)}
      >
        <img src={plusIcon} height={15} />
        <label className='theme-text-green-1 theme-size-0_9 cursor-pointer'>
          Add Workflow Logic
        </label>
        <label></label>
      </div>
    </div>
  );
};

SectionWorkFlows.propTypes = {
  supervisorElements: PropTypes.array.isRequired,
  sectionElementIndex: PropTypes.number.isRequired,
  addWorkflowInSection: PropTypes.func.isRequired,
  handleSectionWorkflowChange: PropTypes.func.isRequired,
};

const WorkflowTypeLabel = ({ workflow }) => {
  const { t } = useTranslation();

  console.log({ workflow });

  return (
    <Fragment>
      <label className='theme-text-green-1 theme-size-0_9'>GO TO</label>
      <img src={arrowIcon} />
      <img
        src={
          workflow.type === 'app_user'
            ? userIcon
            : workflow.type === 'end_of_form'
            ? infinityIcon
            : sectionIcon
        }
        height={16}
      />
      <label className='theme-text-green-1 theme-size-0_9'>{t('text_' + workflow.type)}</label>
    </Fragment>
  );
};

WorkflowTypeLabel.propTypes = {
  workflow: PropTypes.object.isRequired,
};

export default SectionWorkFlowContainer;
