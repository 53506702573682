import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import importIcon from 'assets/images/icons/import.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import searchIcon from 'assets/images/icons/search.svg';
import Export from 'components/common-components/Export';
import FormButton from 'components/form-components/FormButton';
import TextField from 'components/form-components/TextField';
import FormActions from './Actions';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import { CallAPI } from 'actions/General';

const FormsListHeader = ({
  actionFunction,
  selectedTab,
  setSearchText,
  searchText,
  selectedRows,
  getAllForms,
  debounceFunc,
  filteringData,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openSelectedFile = (e) => {
    const file = e.target.files[0];

    var reader = new FileReader();

    reader.onload = async function (event) {
      var jsonObj = JSON.parse(event.target.result);

      const result = await CallAPI('SAVE_FORM', jsonObj, null, null, null);
      if (result.status) {
        toast.success('Form imported successfully');
      }
      getAllForms();
    };

    reader.readAsText(file);
  };
  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className=' d-flex  align-items-center gap-3  '>
        <TextField
          value={searchText}
          name='search'
          handleChange={setSearchText}
          placeholder={t('field_search')}
          icon={searchIcon}
          debounceFunc={debounceFunc}
          shrink
        />
        {isPermissionAvailable(permissionKeys.field_work_forms_create) && (
          <FormActions
            ids={selectedRows}
            isActionButton={false}
            actionsCallBack={getAllForms}
            type={selectedTab}
            actionData={filteringData}
          />
        )}
      </div>
      <div className={`d-flex align-items-center gap-3`}>
        <FormButton
          text='button_import'
          onClick={handleUploadClick}
          variant='green-2'
          icon={importIcon}
        />
        <input
          className='d-none'
          ref={fileInputRef}
          type='file'
          accept='.midani'
          onChange={openSelectedFile}
        />
        <Export
          type='forms'
          screenTab={selectedTab}
          additionalDetail={{ formIds: selectedRows.map((row) => row.id) }}
        />
        {selectedTab === 'forms' &&
          isPermissionAvailable(permissionKeys.field_work_forms_create) && (
            <FormButton
              text={'button_add_new'}
              onClick={actionFunction ? actionFunction : null}
              variant='green-2'
              icon={plusIcon}
            />
          )}
      </div>
    </div>
  );
};
FormsListHeader.propTypes = {
  actionFunction: PropTypes.func,
  selectedTab: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  getAllForms: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  debounceFunc: PropTypes.func.isRequired,
  filteringData: PropTypes.object.isRequired,
};
export default FormsListHeader;
