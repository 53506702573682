import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import fieldCollapseIcon from 'assets/images/icons/field-collapse.svg';
import FieldDetailView from './FieldDetailView';
import SwappingComponent from './SwappingComponent';
import { isValues, supervisorFieldList } from '../utils';
import NestedSelect from 'components/form-components/NestedSelect';
import chevronGreyIcon from 'assets/images/icons/chevron-grey.svg';
import photoIcon from 'assets/images/icons/photo.svg';
import plusGreyIcon from 'assets/images/icons/plus-grey-2.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import movieIcon from 'assets/images/icons/movie.svg';
import crossGreyIcon from 'assets/images/icons/cross-grey.svg';
import chevronIcon from 'assets/images/icons/chevron.svg';
import messageQuestionCircleIcon from 'assets/images/icons/message-question-circle.svg';
import { isRTL } from 'config/config';
import ConfirmationModal from 'components/common-components/ConfirmationModal';

const fieldTypeMap = Object.fromEntries([...supervisorFieldList].map((f) => [f.elementType, f]));

const SupervisorFieldComponent = ({
  field,
  hideSwapping,
  selectComponent,
  direction,
  depth,
  changeFormElementOrder,
  isOpened,
  isSelected,
  elementIndex,
  setFieldDragged,
  checkSwapArrow,
  addTriggerInElement,
  handleTriggerElementChange,
  addTriggerType,
  removeTriggerType,
  supervisorElements,
  selectedField,
}) => {
  const isLanRTL = isRTL();
  const { t } = useTranslation();

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [triggerDeletionProps, setTriggerDeletionProps] = useState([]);
  const [options, setOptions] = useState(
    (field.properties.basicProperties?.optionList ?? []).map((option) => ({
      value: option.value,
      displayValue: option.responseText,
    })),
  );

  const checkIfDisabled = () => {
    return (field.properties?.basicProperties?.multipleChoice ?? false) || options.length === 0;
  };

  const triggerValues = [
    {
      value: 'ask_a_question',
      displayValue: 'Ask Question',
      disabled: depth > 2,
      nestedOptions: [...supervisorFieldList]
        .filter((f) =>
          [
            'supervisor_note',
            'supervisor_dateTime',
            'supervisor_response',
            'supervisor_signature',
            'supervisor_number',
            'supervisor_ratingSlider',
          ].includes(f.elementType),
        )
        .map((f) => ({
          value: f.elementType,
          displayValue: f.displayName,
        })),
      subOptionsArrowClass: 'invisible',
      subOptionsSelectClass: 'trigger_type',
    },
    { value: 'require_picture', displayValue: 'Require Picture' },
    { value: 'require_video', displayValue: 'Require Video' },
  ];

  const handleTriggerTypeChange = (name, value, triggerIndex) => {
    if (name === 'ask_a_question') {
      addTriggerType('supervisor_response', elementIndex, triggerIndex, name, value);
    } else {
      addTriggerType('supervisor_response', elementIndex, triggerIndex, value);
    }
  };

  const getDisplayValue = (list, checkValue, defaultValue = '') => {
    return list.find((o) => o.value === checkValue)?.displayValue ?? defaultValue;
  };

  useEffect(() => {
    if (field.elementType === 'supervisor_response' && field.isOpen) {
      setOptions(
        (field.properties.basicProperties?.optionList ?? []).map((option) => ({
          value: option.value,
          displayValue: option.responseText,
        })),
      );
    }
  }, [field]);

  return (
    <Fragment>
      <div
        className={`field-component-new${isSelected ? ' selected' : ''} ${
          isOpened ? ' opened' : ''
        }`}
      >
        <div
          className='field-component-header'
          draggable={!field.childReferenceId && field.elementType !== 'supervisor_response'}
          onDragStart={() =>
            setFieldDragged({ fieldCategory: 'supervisor-field', position: elementIndex })
          }
          onDragEnd={(e) => {
            if (e.dataTransfer.dropEffect === 'none') setFieldDragged(null);
          }}
        >
          <div className='d-flex gap-2 justify-content-between flex-grow-1'>
            <div
              className='d-flex align-items-center gap-2 flex-grow-1 cursor-pointer'
              onClick={() => selectComponent(elementIndex, field.elementType)}
            >
              <img
                src={fieldCollapseIcon}
                className={`
                ${isOpened ? '' : `rotate-${direction === 'rtl' ? '90' : '270'}`}
                ${isSelected ? '' : 'svg-grey'}
              `}
              />
              <label className='cursor-pointer theme-size-0_9'>
                {field.properties?.basicProperties?.fieldTitle
                  ? field.properties?.basicProperties?.fieldTitle
                  : t('form_untitled_field')}
              </label>
            </div>
          </div>
          <div className='d-flex gap-2'>
            <div className='element-icon'>
              <img src={fieldTypeMap[field.elementType].icon} />
            </div>
            {!hideSwapping && (
              <div className='d-flex gap-2'>
                <SwappingComponent
                  elementType={field.elementType}
                  type='supervisor_field'
                  props={{ position: elementIndex }}
                  changeFormElementOrder={changeFormElementOrder}
                  isSelected={isSelected}
                  {...checkSwapArrow(elementIndex, field.elementType, 'supervisor-field')}
                />
              </div>
            )}
          </div>
        </div>
        <div className='field-component-body'>
          <p>
            {field.properties?.basicProperties?.fieldHintText
              ? field.properties?.basicProperties?.fieldHintText
              : t('form_hint')}
          </p>
          <FieldDetailView element={field} dataSourcesList={[]} />

          {field.elementType === 'supervisor_response' && (
            <Fragment>
              {field.triggers?.map((trigger, triggerIndex) => (
                <div key={triggerIndex} className='d-flex gap-2 mx-1 mt-3'>
                  <div
                    className={`border-green-1-h-2 border-${
                      direction === 'rtl' ? 'start' : 'end'
                    }-0 px-1`}
                  ></div>
                  <div className='flex-grow-1 p-2'>
                    <div
                      className={`d-flex gap-2 flex-wrap align-items-center ${
                        (direction === 'ltr') === isLanRTL ? 'justify-content-end' : ''
                      }`}
                      style={{ direction: isLanRTL ? 'rtl' : 'ltr' }}
                    >
                      <label className='theme-size-0_9'>{t('form_field_if_answer')}</label>
                      <NestedSelect
                        options={isValues.filter(
                          (o) =>
                            !field.triggers.find(
                              (t, index) =>
                                triggerIndex !== index &&
                                t.matcher === o.value &&
                                t.matchingValue === trigger.matchingValue,
                            ),
                        )}
                        handleChange={(name, value) =>
                          handleTriggerElementChange(
                            'supervisor_response',
                            elementIndex,
                            triggerIndex,
                            name,
                            value,
                          )
                        }
                        name='matcher'
                        toggleClasses='px-1'
                        toggleComponent={
                          <>
                            <label className='theme-size-0_9 cursor-pointer'>
                              {t(getDisplayValue(isValues, trigger.matcher))}
                            </label>
                            <img src={chevronGreyIcon} className='rotate-180' />
                          </>
                        }
                      />
                      <NestedSelect
                        options={options.filter(
                          (o) =>
                            !field.triggers.find(
                              (t, i) =>
                                i !== triggerIndex &&
                                t.matcher === trigger.matcher &&
                                t.matchingValue === o.value,
                            ),
                        )}
                        handleChange={(name, value) =>
                          handleTriggerElementChange(
                            'supervisor_response',
                            elementIndex,
                            triggerIndex,
                            name,
                            value,
                          )
                        }
                        name='matchingValue'
                        toggleClasses='px-2 py-1 border-grey-1-h-1'
                        toggleComponent={
                          <>
                            <label className='theme-size-0_9 cursor-pointer'>
                              {t(getDisplayValue(options, trigger.matchingValue, 'select_value'))}
                            </label>
                            <img src={chevronGreyIcon} className='rotate-180' />
                          </>
                        }
                      />
                      {trigger.matchingValue && (
                        <Fragment>
                          <label className='mx-2 theme-size-0_9'>{t('text_then')}</label>
                          {trigger.types.map(({ type }, typeIndex) => (
                            <div
                              key={typeIndex}
                              className='d-flex gap-2 theme-background-green-4 p-2 rounded-sm'
                            >
                              <img
                                className='cursor-pointer'
                                src={
                                  type === 'require_picture'
                                    ? photoIcon
                                    : type === 'require_video'
                                    ? movieIcon
                                    : messageQuestionCircleIcon
                                }
                              />
                              <label className='theme-text-green-1 theme-size-0_9'>
                                {t('text_' + type)}
                              </label>
                              <img
                                className='cursor-pointer'
                                onClick={() => {
                                  setDeleteConfirmationModal(true);
                                  setTriggerDeletionProps([
                                    'supervisor_response',
                                    elementIndex,
                                    triggerIndex,
                                    typeIndex,
                                  ]);
                                  // removeTriggerType(elementIndex, triggerIndex, typeIndex)
                                }}
                                src={crossGreyIcon}
                              />
                            </div>
                          ))}

                          <NestedSelect
                            options={triggerValues}
                            handleChange={(name, value) =>
                              handleTriggerTypeChange(name, value, triggerIndex)
                            }
                            name='type'
                            toggleClasses='px-1 py-2'
                            toggleComponent={
                              <>
                                <label className='theme-size-0_9 cursor-pointer theme-text-green-1'>
                                  {t('form_field_trigger')}
                                </label>
                                <img src={chevronIcon} className='rotate-180' />
                              </>
                            }
                          />
                        </Fragment>
                      )}
                    </div>

                    <div className='child-elements'>
                      {supervisorElements.map((childElement, index) => (
                        <Fragment key={index}>
                          {childElement.childReferenceId &&
                            trigger.types.find(
                              (type) => type.childReferenceId === childElement.childReferenceId,
                            ) && (
                              <div className='mt-3'>
                                <SupervisorFieldComponent
                                  elementIndex={index}
                                  field={childElement}
                                  hideSwapping
                                  direction={direction}
                                  depth={depth + 1}
                                  selectComponent={selectComponent}
                                  changeFormElementOrder={changeFormElementOrder}
                                  isOpened={childElement.isOpen}
                                  isSelected={index === selectedField?.position}
                                  selectedField={selectedField}
                                  setFieldDragged={setFieldDragged}
                                  supervisorElements={supervisorElements}
                                  addTriggerInElement={addTriggerInElement}
                                  handleTriggerElementChange={handleTriggerElementChange}
                                  addTriggerType={addTriggerType}
                                  removeTriggerType={removeTriggerType}
                                  checkSwapArrow={checkSwapArrow}
                                  // currentPage={currentPage}
                                  // setFieldDragged={setFieldDragged}
                                />
                              </div>
                            )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {depth < 4 && (
                <div
                  className={`if${checkIfDisabled() ? ' disabled' : ''}`}
                  onClick={() =>
                    checkIfDisabled()
                      ? null
                      : addTriggerInElement('supervisor_response', elementIndex)
                  }
                >
                  <img src={checkIfDisabled() ? plusGreyIcon : plusIcon} />
                  <label className='theme-text-green-1'>IF</label>
                  <label></label>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_form_element_deletion_title')}
        message={t('alert_trigger_deletion_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => {
              setTriggerDeletionProps([]);
              setDeleteConfirmationModal(false);
            },
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              if (triggerDeletionProps.length) removeTriggerType(...[...triggerDeletionProps]);
              setTriggerDeletionProps([]);
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </Fragment>
  );
};

SupervisorFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  hideSwapping: PropTypes.bool,
  selectComponent: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  elementIndex: PropTypes.number.isRequired,
  setFieldDragged: PropTypes.func.isRequired,
  checkSwapArrow: PropTypes.func.isRequired,
  addTriggerInElement: PropTypes.func.isRequired,
  handleTriggerElementChange: PropTypes.func.isRequired,
  addTriggerType: PropTypes.func.isRequired,
  removeTriggerType: PropTypes.func.isRequired,
  supervisorElements: PropTypes.array.isRequired,
  selectedField: PropTypes.object,
};

export default SupervisorFieldComponent;
