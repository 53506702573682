import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';

const CustomMentionsComponent = ({
  name,
  placeholder,
  value,
  handleChange,
  updateMentionsMap,
  resetMentionsInput,
  setResetMentionsInput,
  systemElementsHeadingArray,
  systemElementsOptionArray,
  formElementsHeadingArray,
  formElemetsOptionArray,
}) => {
  const { t } = useTranslation();
  const triggerChar = '#';
  const wrappingStartString = '#[';
  const wrappingEndString = ']#';
  const [fieldValue, setFieldValue] = useState(value || '');
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    // reset fieldValue and mapping object onclick of reset button
    if (resetMentionsInput && fieldValue) {
      setResetMentionsInput(false);
      updateMentionsMap({});
      setFieldValue('');
      handleChange(name, '');
      handleLanguageDirection('');
    }
  }, [resetMentionsInput]);

  const mentionsOptionArray = [...systemElementsOptionArray, ...formElemetsOptionArray];

  const handleLanguageDirection = (textValue) => {
    if (textValue.length > 0) {
      // Check if the first character is not an English letter or number
      const firstChar = textValue[0];
      const isFirstCharRTL = !/^[a-zA-Z0-9]/.test(firstChar); // Regex checks for non-alphanumeric characters
      if (isFirstCharRTL !== isRTL) {
        setIsRTL(isFirstCharRTL); // Set RTL if the first character is not English or a number
      }
    } else {
      // Reset to left-to-right if the textValue is empty
      setIsRTL(false);
    }
  };

  const handleClose = () => {
    setDropdownVisible(false);
  };

  const modifyMappingObject = (InputTextValue) => {
    // Function to escape special characters in a string for regex
    function escapeRegExp(string) {
      // Escape all special regex characters
      return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'); // eslint-disable-line
    }
    // Escape special characters in the wrapping strings
    const regex = new RegExp(
      `${escapeRegExp(wrappingStartString)}(.*?)${escapeRegExp(wrappingEndString)}`,
      'g',
    );
    // Use matchAll() to find all matches and map the results to remove extra characters
    const matches = [...InputTextValue.matchAll(regex)].map((match) => match[1]);
    const UpdatedMentionsMapObj = {};
    matches.forEach((usedMention) => {
      mentionsOptionArray.forEach((item) => {
        if (item.display === usedMention) {
          UpdatedMentionsMapObj[item.display] = item.value;
        }
      });
    });
    updateMentionsMap(UpdatedMentionsMapObj);
  };

  const handleInputChange = (event) => {
    // handle mention input field direction
    handleLanguageDirection(event.target.value);
    // create latest mapping object using current mention input field value
    modifyMappingObject(event.target.value);
    // save value to formProperties
    handleChange(name, event.target.value);
    // update mention input field value
    setFieldValue(event.target.value);
    // Check if the trigger character is typed to show the dropdown again
    if (event.target.value.includes(triggerChar) && !dropdownVisible) {
      setDropdownVisible(true); // Show the dropdown when '@' is typed
    }
  };

  return (
    <MentionsInput
      value={fieldValue}
      onChange={handleInputChange}
      placeholder={placeholder}
      className='mentions'
      style={{
        direction: isRTL ? 'rtl' : 'ltr',
        opacity: !fieldValue ? '0.6' : '1',
      }}
      customSuggestionsContainer={(children) => {
        if (!dropdownVisible) return null;
        let renderedFormElemHeadingItems = false;
        return (
          <div className='custom-mentions-list-wrapper py-1 px-2'>
            <div className='mention-suggestions'>
              {children.props.children.map((child, index) => {
                const suggestion = child.props.suggestion;
                return (
                  <div key={index}>
                    {index === 0 &&
                      systemElementsHeadingArray.map((el, i) => (
                        <div key={i} className='mention-item mention-heading'>
                          {el.display}
                        </div>
                      ))}

                    {suggestion.type === 'formField' &&
                      !renderedFormElemHeadingItems &&
                      formElementsHeadingArray.map((el, i) => {
                        renderedFormElemHeadingItems = true;
                        return (
                          <div className='mention-item mention-heading' key={i}>
                            {el.display}
                          </div>
                        );
                      })}
                    <div className='mention-item'>{child}</div>
                  </div>
                );
              })}
            </div>
            <hr className='mx-0 my-1'></hr>
            <div className='mention-dropdown-footer' onClick={handleClose}>
              {t('text_close')}
            </div>
          </div>
        );
      }}
    >
      <Mention
        trigger={triggerChar}
        data={mentionsOptionArray}
        markup='#[__display__]#'
        className='mentions__mention'
      />
    </MentionsInput>
  );
};

CustomMentionsComponent.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  updateMentionsMap: PropTypes.func,
  resetMentionsInput: PropTypes.bool,
  setResetMentionsInput: PropTypes.func,
  systemElementsHeadingArray: PropTypes.array,
  systemElementsOptionArray: PropTypes.array,
  formElementsHeadingArray: PropTypes.array,
  formElemetsOptionArray: PropTypes.array,
};

export default CustomMentionsComponent;
