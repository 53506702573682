import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import closeGreyIcon from 'assets/images/icons/cross-grey.svg';
import Loader from 'components/common-components/Loader';
import TableComponent from 'components/table-component/Table';
import { submissionsLogsHeader } from './utils';
import { CallAPI } from 'actions/General';

function SubmissionLogs({ submissionId, setSubmissionId }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  const getSubmissionLogs = async () => {
    const result = await CallAPI(
      'FETCH_SUBMISSION_LOGS',
      null,
      submissionId,
      setLoader,
      null,
      null,
    );
    if (result.status) {
      setData(result.data);
      console.log(result.data);
    }
  };

  useEffect(() => {
    if (submissionId) {
      getSubmissionLogs();
    }
  }, [submissionId]);
  const displayLogMessage = (message) => {
    setDisplayMessage(message);
  };
  return (
    <>
      <div className='modal custom-modal'>
        <div className='d-flex flex-column theme-width-1000 px-5 position-relative'>
          <div
            className='position-absolute top-0 end-0 mx-3 my-2 cursor-pointer'
            onClick={() => setSubmissionId('')}
          >
            <img src={closeGreyIcon} />
          </div>
          {loader && <Loader color='green-1' type='logs_loader' />}
          <TableComponent
            header={submissionsLogsHeader}
            data={data}
            props={{
              type: 'submission_logs',
              displayLogMessage,
              translateValueFunc: (val) => t(val),
            }}
          />
        </div>
      </div>
      {displayMessage && (
        <div className='modal custom-modal'>
          <div className='d-flex flex-column theme-width-633 px-5 position-relative'>
            <div
              className='position-absolute top-0 end-0 mx-3 my-2 cursor-pointer'
              onClick={() => setDisplayMessage('')}
            >
              <img src={closeGreyIcon} />
            </div>
            <h4>Sent Message</h4>
            <p className='theme-font-inter-medium log-message_detail'>{displayMessage}</p>
          </div>
        </div>
      )}
    </>
  );
}
SubmissionLogs.propTypes = {
  submissionId: PropTypes.string.isRequired,
  setSubmissionId: PropTypes.func.isRequired,
};
export default SubmissionLogs;
