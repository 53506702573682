import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, sortBy } from 'lodash';
// import checkBoxSquareIcon from 'assets/images/icons/check-box-square.svg';
import linkExternalIcon from 'assets/images/icons/link-external.svg';
import AlertModal from 'components/common-components/AlertModal';
import Export from 'components/common-components/Export';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Loader from 'components/common-components/Loader';
import PrepareErrorList from './PrepareErrorList';
import PreviewModal from './PreviewModal';
import { DEV_ONLY, updateSavedChanges, updateTriggerSaveFormChanges } from 'config/config';
import { CallAPI } from 'actions/General';
import {
  createAPIStructure,
  createStructureForExportJson,
  jsonStringifyAllElements,
  removeUnbindElementUniqueIds,
  uploadImageFieldsMedia,
} from './actions';

function FormDesignHeader({ formDetails, getFormDetail, setAPIError, formId, formProperties }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorsList, setErrorsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState('');
  const [successModalType, setSuccessModalType] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSaveAsDraftModal, setShowSaveAsDraftModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [formUserElements, setFormUserElements] = useState([]);
  const [formSupervisorElements, setFormSupervisorElements] = useState([]);
  const { lang } = useSelector((state) => state.language);
  const hasUnsavedChanges = useSelector((state) => state.auth.hasUnsavedChanges);
  const unsavedChangedType = useSelector((state) => state.auth.unsavedChangedType);
  const triggerSaveFormChanges = useSelector((state) => state.auth.triggerSaveFormChanges);

  const validateAndSave = async () => {
    setLoaderType('save');
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      await uploadImageFieldsMedia(APIData.elements, formDetails);
      APIData = jsonStringifyAllElements(APIData);
      APIData.elements = removeUnbindElementUniqueIds(APIData.elements, formProperties);
      let formSaveData = {
        formId,
        elements: APIData.elements,
        supervisorElements: APIData.supervisorElements,
        statusElement: APIData.statusElements.length > 0 ? APIData.statusElements[0] : null,
      };
      let result = await CallAPI('SAVE_FORM', formSaveData, null, setLoader, setAPIError);
      updateSavedChanges(false);
      if (result.status) {
        getFormDetail(formDetails._id);
        setSuccessModalType('save');
      }
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };
  const publish_form = async () => {
    setLoaderType('publish');
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      updateSavedChanges(false);
      const response = await CallAPI(
        'PUBLISH_FORM',
        { formId: formDetails._id, locale: lang },
        null,
        setLoader,
        setAPIError,
        null,
      );
      if (response.status) {
        getFormDetail(formDetails._id);
        setSuccessModalType('publish');
      }
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };
  const navigateToTestSubmissions = () => {
    navigate('/submissions', {
      relative: 'path',
      state: {
        type: 'edit',
        status: 'testing',
        formId: formDetails._id,
        version: '',
        versionsList: formDetails.versions,
      },
    });
  };
  const updateFormTestingStatus = async () => {
    let formState = formDetails.versions[formDetails.versions.length - 1].formState;
    setLoaderType('testing');
    const response = await CallAPI(
      'UPDATE_FORM_TESTING',
      {
        formId: formDetails._id,
        startTesting: formState !== 'testing' ? true : false,
        locale: lang,
      },
      null,
      setLoader,
      setAPIError,
      null,
    );
    if (response.status) {
      getFormDetail(formDetails._id);
      setSuccessModalType('testing');
    }
  };
  useEffect(async () => {
    if (
      triggerSaveFormChanges &&
      triggerSaveFormChanges.trigger &&
      unsavedChangedType === 'FORM_DESIGN'
    ) {
      setShowSaveAsDraftModal(true);
      await validateAndSave();
      updateTriggerSaveFormChanges(false);
      setShowSaveAsDraftModal(false);
    }
  }, [triggerSaveFormChanges]);

  const exportJson = () => {
    let APIData = createAPIStructure(cloneDeep(formProperties));
    if (APIData.status) {
      APIData.elements = createStructureForExportJson(APIData.elements);
      APIData.supervisorElements = createStructureForExportJson(APIData.supervisorElements);
      APIData.statusElements = createStructureForExportJson(APIData.statusElements);
      APIData = jsonStringifyAllElements(APIData);

      const jsonData = {
        title: formDetails.title,
        formIcon: formDetails.formIcon ?? 'attendance',
        layoutSettings: formDetails.layoutSettings,
        elements: APIData.elements,
        supervisorElements: APIData.supervisorElements,
        statusElement: APIData.statusElements.length > 0 ? APIData.statusElements[0] : null,
      };
      // Convert JSON to string
      const jsonString = JSON.stringify(jsonData, null, 2);

      // Create a Blob object with the JSON data
      const blob = new Blob([jsonString], { type: 'application/json' });
      let anchorElement = document.getElementById('downloadJsonFile');
      anchorElement.setAttribute('href', window.URL.createObjectURL(blob));
      anchorElement.setAttribute('download', formDetails.title + '.midani');
      anchorElement.click();
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };

  const validateAndCheckOrder = () => {
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      APIData = jsonStringifyAllElements(APIData);
      setShowOrderModal(!showOrderModal);
      setFormUserElements(APIData.elements);
      setFormSupervisorElements(APIData.supervisorElements);
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };

  const validateAndPreview = () => {
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      setShowPreviewModal(!showPreviewModal);
      setFormUserElements(APIData.elements);
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };

  return (
    <div className='sub-header'>
      <label className='d-flex align-items-center theme-font-inter-medium theme-text-black-1 theme-size-1_1'>
        {formDetails?.title ?? ''}
      </label>
      <div className='d-flex gap-3 justify-content-end'>
        <div className='d-flex gap-2'>
          {/* <FormButton text='button_import' variant='white-1' /> */}
          {DEV_ONLY && (
            <>
              <FormButton
                text='button_check_order'
                variant='blue-2'
                // disabled={!formUserElements.length}
                onClick={validateAndCheckOrder}
              />
            </>
          )}
          <FormButton
            text='button_preview'
            variant='blue-3'
            // disabled={!formUserElements.length}
            onClick={validateAndPreview}
          />
          <FormButton
            text='button_view_testing'
            variant='grey-1'
            onClick={navigateToTestSubmissions}
          />
          <FormLoadingButton
            disabled={
              formDetails &&
              sortBy(formDetails.versions, (e) => parseInt(e.version))[
                formDetails.versions.length - 1
              ].formState === 'published'
            }
            text={
              formDetails &&
              formDetails.versions.length > 0 &&
              sortBy(formDetails.versions, (e) => parseInt(e.version))[
                formDetails.versions.length - 1
              ].formState !== 'testing'
                ? 'button_start_testing'
                : 'button_stop_testing'
            }
            variant='orange-2'
            iconClasses='svg-white'
            onClick={updateFormTestingStatus}
            loading={loader && loaderType === 'testing' ? true : false}
          />
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={validateAndSave}
            loading={loader && loaderType === 'save' ? true : false}
          />
        </div>
        <div className='d-flex align-items-center gap-3'>
          <a className='d-none' id='downloadJsonFile' download></a>
          <FormLoadingButton
            text='button_export_json'
            variant='green-2'
            icon={linkExternalIcon}
            iconRight
            disabled={hasUnsavedChanges}
            onClick={exportJson}
            loading={loader && loaderType === 'export' ? true : false}
          />
          <Export
            type={'form_design'}
            screenTab={'design'}
            additionalDetail={{
              form: { id: formDetails?._id ?? '', title: formDetails?.title ?? '' },
            }}
          />
          <FormLoadingButton
            text='button_publish'
            onClick={publish_form}
            loading={loader && loaderType === 'publish' ? true : false}
            disabled={hasUnsavedChanges}
            variant='blue-1'
          />
        </div>
      </div>
      <AlertModal
        show={successModalType !== ''}
        showCloseButton={true}
        closeModal={() => {
          setLoaderType('');
          setSuccessModalType('');
        }}
        title='alert_success'
        message={
          successModalType === 'publish'
            ? 'alert_form_published'
            : successModalType === 'testing'
            ? formDetails &&
              formDetails.versions[formDetails.versions.length - 1].formState !== 'testing'
              ? 'alert_form_start_testing'
              : 'alert_form_stop_testing'
            : 'alert_form_saved'
        }
        variant='success'
        actionButtons={[
          {
            text: t('button_ok'),
            variant: 'green-1',
            onClick: () => {
              setLoaderType('');
              setSuccessModalType('');
            },
          },
        ]}
      />
      {showSaveAsDraftModal && (
        <AlertModal show={showSaveAsDraftModal} actionButtons={[]}>
          <div className='d-flex flex-column gap-4 align-items-center'>
            <label className='theme-size-1_5 theme-text-black-1'>Saving as Draft</label>
            <Loader color='green-1' />
          </div>
        </AlertModal>
      )}
      {formDetails && showPreviewModal && (
        <PreviewModal
          setShowPreviewModal={setShowPreviewModal}
          formTitle={formDetails.title}
          formIcon={formDetails.formIcon ? formDetails.formIcon : ''}
          userFields={formUserElements}
        />
      )}
      {formDetails && showOrderModal && (
        <AlertModal
          show={showOrderModal}
          showCloseButton={true}
          closeModal={() => setShowOrderModal(false)}
          title='alert_order'
          size='xl'
          data={ShowOrder({
            userFields: formUserElements,
            supervisorFields: formSupervisorElements,
          })}
          actionButtons={[
            { text: t('button_ok'), variant: 'grey-1', onClick: () => setShowOrderModal(false) },
          ]}
        />
      )}

      <AlertModal
        show={showErrorModal}
        showCloseButton={true}
        closeModal={() => setShowErrorModal(false)}
        title='alert_warning'
        message='alert_form_missing_properties'
        data={PrepareErrorList({ errorMessages: cloneDeep(errorsList) })}
        variant='delete'
        actionButtons={[
          { text: t('button_ok'), variant: 'grey-1', onClick: () => setShowErrorModal(false) },
        ]}
      />
    </div>
  );
}
FormDesignHeader.propTypes = {
  getFormDetail: PropTypes.func.isRequired,
  formDetails: PropTypes.object.isRequired,
  formProperties: PropTypes.object.isRequired,
  setAPIError: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
};
export default FormDesignHeader;

const ShowOrder = ({ userFields, supervisorFields }) => {
  return (
    <div className='d-flex flex-column gap-3 w-100'>
      {[userFields, supervisorFields].map((list, i) => (
        <div key={i} className='border-grey-1-h-1 d-flex flex-column gap-2 py-2'>
          {list.map((element, index) => (
            <div
              key={index}
              className={`d-flex gap-2 justify-content-between ${
                index < list.length - 1 ? 'border-bottom-grey-1-h-1' : ''
              } ${
                element.elementType === 'page'
                  ? 'theme-background-green-3'
                  : element.elementType === 'section'
                  ? 'theme-background-grey-13'
                  : ''
              }`}
            >
              <label style={{ width: '20%' }}>Element: {element.elementType}</label>
              <label style={{ width: '20%' }}>
                Title:{' '}
                {JSON.parse(element.properties)?.basicProperties?.fieldTitle ??
                  JSON.parse(element.properties)?.basicProperties?.sectionTitle ??
                  ''}
              </label>
              <label style={{ width: '20%' }}>Page: {element.pageNumber}</label>
              <label style={{ width: '20%' }}>Section: {element.sectionNumber}</label>
              <label style={{ width: '20%' }}>Position: {element.position}</label>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

ShowOrder.propTypes = {
  userFields: PropTypes.array.isRequired,
  supervisorFields: PropTypes.array.isRequired,
};
