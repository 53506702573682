import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import editGreenIcon from 'assets/images/icons/edit-green.svg';
import selectArrow from 'assets/images/icons/arrow-down.svg';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import SelectField from 'components/form-components/SelectField';
import SelectIconField from 'components/form-components/SelectIconField';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';

function Form({ values, errors, touched, handleChange, APIError }) {
  const { t } = useTranslation();

  const textDirectionValues = [
    { value: 'left_to_right', displayValue: 'select_left_to_right' },
    { value: 'right_to_left', displayValue: 'select_right_to_left' },
  ];

  const textDirectionValuesMap = Object.fromEntries(
    textDirectionValues.map((v) => [v.value, v.displayValue]),
  );
  const [openColorModal, setOpenColorModal] = useState(false);

  return (
    <div className='pb-3'>
      {APIError && <Error msg={APIError} />}
      <TextField
        name='title'
        displayName='field_form_title'
        placeholder='field_form_title'
        value={values.title}
        error={errors.title}
        touched={touched.title}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        shrinkFieldOnly
        horizontalLabelClass={'min-width-176'}
      />

      <SelectIconField
        handleChange={handleChange}
        name='formIcon'
        placeholder='field_form_icon'
        label={values.formIcon}
        icon={selectArrow}
        variant='white-1'
        isAutoClose={true}
        isHorizontal={true}
        iconType='form'
      />
      <div className='space-4'></div>
      <SelectField
        name='textDirection'
        handleChange={handleChange}
        listValues={textDirectionValues}
        placeholder='field_form_direction'
        label={textDirectionValuesMap[values.textDirection] ?? 'select_select'}
        icon={selectArrow}
        variant='white-1'
        menuClasses='w-100'
        isAutoClose={true}
        isHorizontal={true}
      />
      <div className='space-4'></div>
      <div className='d-flex align-items-center mb-3'>
        <label className='min-width-176'>{t('text_submit_button_color')}</label>
        <div className='d-flex w-100 gap-3'>
          <div
            className='w-100 py-2 rounded-sm text-center theme-text-white-1'
            style={{ backgroundColor: values.submitButtonColor }}
          >
            {t('text_preview_submit')}
          </div>
          <div className='position-relative'>
            <FormButton
              text='button_edit'
              variant='green-2'
              onClick={() => setOpenColorModal(true)}
              icon={editGreenIcon}
              iconRight
              minWidth
            />
            {openColorModal && (
              <ColorChangeModal
                name='submitButton'
                closeModal={() => setOpenColorModal(false)}
                styleProperties={values}
                handleChange={handleChange}
                hideFontSizeOption
              />
            )}
          </div>
        </div>
      </div>
      <TextArea
        name='description'
        displayName='field_description'
        value={values.description}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        rows={3}
        horizontalLabelClass={'min-width-176'}
      />
      <div className='d-flex align-items-center mb-2'>
        <label className='min-width-176'>{t('text_allow_logs')}</label>
        <ToggleSwitch name='allowLogs' value={values.allowLogs} handleChange={handleChange} />
      </div>
    </div>
  );
}
Form.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  APIError: PropTypes.string.isRequired,
};
export default Form;
