import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import arrowLeftGreyIcon from 'assets/images/icons/arrow-left-grey.svg';
import downloadIcon from 'assets/images/icons/download.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import AlertModal from 'components/common-components/AlertModal';

const PreviewImageModal = ({
  imageFile,
  existingImgUrl,
  showImageModal,
  setShowImageModal,
  handleUnAttachImg,
}) => {
  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleTrashBtn = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    setShowImageModal(false);
    handleUnAttachImg();
    setShowDeleteModal(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageFile ? URL.createObjectURL(imageFile) : existingImgUrl; // Set the href to the object URL
    link.download = imageFile ? imageFile.name : 'Image'; // Set the filename for download (use the file's name)
    document.body.appendChild(link); // Append the link to the DOM
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Clean up by removing the link after the click
  };

  return (
    <>
      <AlertModal show={showImageModal} closeModal={closeModal} actionButtons={[]} size='lg'>
        <div className='w-100 justify-items-start'>
          <div className='d-flex justify-content-between'>
            <div>
              <img className='cursor-pointer' src={arrowLeftGreyIcon} onClick={closeModal} />
            </div>
            <div className='d-flex'>
              <img className='cursor-pointer' src={downloadIcon} onClick={handleDownload} />
              <img className='cursor-pointer ms-2' src={trashGreyIcon} onClick={handleTrashBtn} />
            </div>
          </div>

          {console.log({ imageFile, existingImgUrl })}
          {(imageFile || existingImgUrl) && (
            <img
              className='mt-2'
              src={imageFile ? URL.createObjectURL(imageFile) : existingImgUrl}
              style={{
                objectFit: 'contain',
                width: '100%',
              }}
            />
          )}
        </div>
      </AlertModal>

      <AlertModal
        show={showDeleteModal}
        showCloseButton={true}
        closeModal={() => setShowDeleteModal(false)}
        // variant='delete'
        title={t('button_delete')}
        message={t('alert_delete_image')}
        headerIcon={null}
        actionButtons={[
          {
            text: t('button_cancel'),
            variant: 'white-1',
            onClick: () => setShowDeleteModal(false),
          },
          {
            text: t('button_delete'),
            variant: 'red-1',
            onClick: () => handleDelete(),
          },
        ]}
      />
    </>
  );
};

PreviewImageModal.propTypes = {
  showImageModal: PropTypes.bool.isRequired,
  setShowImageModal: PropTypes.func.isRequired,
  imageFile: PropTypes.object,
  existingImgUrl: PropTypes.string,
  handleUnAttachImg: PropTypes.func.isRequired,
};

export default PreviewImageModal;
