export const submissionsLogsHeader = [
  {
    name: '',
    isSelected: true,
    displayName: 'table_header_recipient',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'recipient',
  },
  {
    name: '',
    isSelected: true,
    displayName: 'table_header_message',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'message',
    translateVal: true,
    populateFunc: 'displayLogMessage',
    callbackFunc: 'displayLogMessage',
  },
  {
    name: '',
    isSelected: true,
    displayName: 'table_header_status',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    translateVal: true,
    associatedKey: 'messageStatus',
    populateFunc: 'displayTranslatedMessage',
  },
  {
    name: '',
    isSelected: true,
    displayName: 'table_header_type',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'logType',
  },
  {
    name: '',
    isSelected: true,
    displayName: 'table_header_date_and_time',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'createdAt',
    populateFunc: 'populateDateTime',
  },
];
export const submissionsHeader = [
  {
    name: 'sr',
    isSelected: true,
    displayName: 'table_header_no',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'indexed',
  },
  {
    name: 'userName',
    isSelected: true,
    displayName: 'table_header_user_name',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    populateFunc: 'databaseUpdatedBy',
  },
  {
    name: 'submitted',
    isSelected: true,
    displayName: 'table_header_date_submitted',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'createdAtTime',
    populateFunc: 'populateDate',
  },
  {
    name: 'webView',
    isSelected: true,
    displayName: 'table_header_web_view',
    isFilterAble: false,
    type: 'webView',
    tableType: 'user',
    associatedKey: 'webView',
    populateFunc: 'populateWebViewIcon',
    callbackFunc: 'gotoRecordPreviewPage',
  },
  {
    name: 'time',
    isSelected: true,
    displayName: 'table_header_time',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'createdAtTime',
    populateFunc: 'populateTime',
  },
  {
    name: 'referenceNumber',
    isSelected: true,
    displayName: 'table_header_reference_number',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'referenceNumber',
  },
  {
    name: 'viewLogs',
    isSelected: true,
    displayName: 'table_header_view_logs',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: '',
    populateFunc: 'populateViewLogs',
    callbackFunc: 'preViewLogs',
  },
];
export const stringOperators = [
  { value: 'equal', displayValue: 'Equal' },
  { value: 'not_equal', displayValue: 'Not Equal' },
  { value: 'contains', displayValue: 'Contains' },
  // { value: 'starts_with', displayValue: 'StartsWith' },
  // { value: 'ends_with', displayValue: 'EndsWith' },
];
export const numberOperators = [
  { value: 'equal', displayValue: 'Equals' },
  { value: 'not_equal', displayValue: 'NotEquals' },
  { value: 'greater_than', displayValue: 'Greater Than' },
  { value: 'greater_than_equal_to', displayValue: 'GreaterThanEqualTo' },
  { value: 'less_than', displayValue: 'LessThan' },
  { value: 'less_than_equal_to', displayValue: 'LessThanEqualTo' },
];
export const singleChoiceOperators = [
  { value: 'equal', displayValue: 'Equals' },
  { value: 'not_equal', displayValue: 'NotEquals' },
];
export const multiChoiceOperators = [
  { value: 'in_array', displayValue: 'In Array' },
  { value: 'not_in_array', displayValue: 'Not In Array' },
];
export const conditionOperators = [
  { value: 'op_and', displayValue: 'AND' },
  { value: 'op_or', displayValue: 'OR' },
];

export const getApprovalStatusVariant = (approvalStatus) =>
  approvalStatus === 'ready'
    ? 'green-1'
    : approvalStatus === 'not_ready'
    ? 'red-1'
    : approvalStatus === 'incomplete'
    ? 'orange-2'
    : 'white-1';
