import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const IconSelectionField = ({ name, value, label, handleChange, classes, iconOptions, shrink }) => {
  const { t } = useTranslation();
  const shrinkLabel = shrink ? ' theme-size-0_8' : '';
  const shrinkIcon = shrink ? ' shrink' : '';

  return (
    <div className={`icon-selection-field ${classes ?? ''}`}>
      <label className={shrinkLabel}>{t(label)}</label>
      <div className='d-flex gap-2'>
        {iconOptions.map((item, index) => (
          <div
            key={index}
            className={`icon-item${shrinkIcon} ${
              value === item.value ? 'theme-background-green-1' : ''
            }`}
            onClick={() => handleChange(name, item.value)}
          >
            <img
              className={`cursor-pointer ${value === item.value ? '' : 'svg-black'}`}
              src={item.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

IconSelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  iconOptions: PropTypes.array.isRequired,
  shrink: PropTypes.bool,
};

export default IconSelectionField;
