import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import infoCircleIcon from 'assets/images/icons/info-circle.svg';
import instructionIcon from 'assets/images/icons/instruction-grey.svg';
import pdfLabelIcon from 'assets/images/icons/pdf-label-icon.svg';
import profileEditIcon from 'assets/images/icons/profile-edit-green-icon.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import PreviewImageModal from './PreviewImageModal';
import TextArea from 'components/form-components/TextArea';
import { deleteSingleFile, uploadSingleFile } from '../actions';
import { CallAPI } from 'actions/General';

const InstructionModal = ({
  showInstructionModal,
  setShowInstructionModal,
  instructionData,
  existingImgUrl,
  handleInstructionChange,
  handleResetInstructions,
  refreshFormInstructions,
  handleCancelInstructions,
  getFormDetail,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [oldImageDeleted, setOldImageDeleted] = useState(false);
  const [oldPdfDeleted, setOldPdfDeleted] = useState(false);
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        handleInstructionChange('imageFile', file);
      } else if (file.type === 'application/pdf') {
        handleInstructionChange('pdfFile', file);
      }
    }
    // reset fileInput
    e.target.value = null;
  };

  const handleUnAttachPdf = () => {
    if (instructionData.pdfMedia) setOldPdfDeleted(true);
    handleInstructionChange('pdfFile', null);
  };
  const handleUnAttachImg = () => {
    if (instructionData.imageMedia) setOldImageDeleted(true);
    handleInstructionChange('imageFile', null);
  };

  const handleMediaUploads = async () => {
    let media = [];
    if (instructionData.imageMedia) media.push(instructionData.imageMedia.id);
    if (instructionData.pdfMedia) media.push(instructionData.pdfMedia.id);

    if (instructionData.imageFile || oldImageDeleted) {
      if (instructionData.imageMedia) {
        await deleteSingleFile(instructionData.imageMedia);
        media = media.filter((mId) => mId !== instructionData.imageMedia.id);
      }
      if (instructionData.imageFile) {
        const imageMediaData = await uploadSingleFile(
          instructionData.imageFile,
          instructionData.formId,
          instructionData.formVersionId,
          'image',
        );
        if (imageMediaData) media.push(imageMediaData.id);
      }
    }
    if (instructionData.pdfFile || oldPdfDeleted) {
      if (instructionData.pdfMedia) {
        await deleteSingleFile(instructionData.pdfMedia);
        media = media.filter((mId) => mId !== instructionData.pdfMedia.id);
      }
      if (instructionData.pdfFile) {
        const pdfMediaData = await uploadSingleFile(
          instructionData.pdfFile,
          instructionData.formId,
          instructionData.formVersionId,
          'file',
        );
        if (pdfMediaData) media.push(pdfMediaData.id);
      }
    }
    return media;
  };

  const handleSave = async () => {
    console.log(instructionData);
    setLoaderType('save');
    setLoading(true);
    try {
      const media = await handleMediaUploads();

      const payload = {
        isEnabled: instructionData.isEnabled,
        description: instructionData.description,
        media,
        updatedDate: new Date(),
      };
      console.log(payload);

      const result = await CallAPI(
        'SAVE_FORM',
        { formId: instructionData.formId, formInstructions: payload },
        null,
        setLoading,
        null,
        null,
      );
      console.log('result', result);

      if (result.status) {
        getFormDetail(instructionData.formId);
        setShowSuccessAlert(true);
        setShowInstructionModal(false);
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
    setLoaderType('');
  };

  const handleRemove = async () => {
    setLoaderType('delete');
    setLoading(true);
    try {
      if (instructionData.imageMedia) await deleteSingleFile(instructionData.imageMedia);
      if (instructionData.pdfMedia) await deleteSingleFile(instructionData.pdfMedia);

      const result = await CallAPI(
        'SAVE_FORM',
        { formId: instructionData.formId, formInstructions: null },
        null,
        setLoading,
        null,
        null,
      );
      console.log('result', result);

      if (result.status) {
        setShowInstructionModal(false);
        setShowSuccessAlert(true);
        getFormDetail(instructionData.formId);
        // disable form instruction checkbox
        handleInstructionChange('isEnabled', false);
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
    setLoaderType('');
  };

  const isUploadMediaDisabled = () => {
    return (
      (instructionData.imageFile || (instructionData.imageMedia && !oldImageDeleted)) &&
      (instructionData.pdfFile || (instructionData.pdfMedia && !oldPdfDeleted))
    );
  };

  useEffect(() => {
    refreshFormInstructions();
  }, []);

  return (
    <>
      <AlertModal show={showInstructionModal} actionButtons={[]}>
        <div className='form-instructions-modal'>
          <div className='w-100 justify-items-start'>
            <div className='form-instructions-modal-header'>
              <img src={instructionIcon} />
              <span className='ms-2 fw-bold fs-5'>{t('form_manage_form_instructions')}</span>
            </div>
            <div className='form-instructions-modal-info'>
              <img src={infoCircleIcon} />
              <span className='ms-2'>{t('form_form_instructions_info')}</span>
            </div>
            <TextArea
              name='description'
              value={instructionData.description}
              placeholder={'form_form_instructions_description_placeholder'}
              displayName='form_description'
              classes='w-100 mb-3'
              handleChange={handleInstructionChange}
              rows={6}
            />

            {(instructionData.pdfFile || (instructionData.pdfMedia && !oldPdfDeleted)) && (
              <div className='d-flex gap-3 border-grey-1-h-1 rounded-sm p-2 width-max-content mb-2'>
                <img src={pdfLabelIcon} />
                <span className='theme-text-green-1'>
                  {instructionData.pdfFile?.name || instructionData.pdfMedia?.imageName}
                </span>
                <img className='cursor-pointer' src={trashGreyIcon} onClick={handleUnAttachPdf} />
              </div>
            )}

            {(instructionData.imageFile || (existingImgUrl && !oldImageDeleted)) && (
              <div className='d-flex gap-3 mb-2'>
                <img
                  src={
                    instructionData.imageFile
                      ? URL.createObjectURL(instructionData.imageFile)
                      : existingImgUrl
                  }
                  style={{ objectFit: 'contain', borderRadius: '5px', width: '90px' }}
                />
                <img
                  className='cursor-pointer'
                  src={profileEditIcon}
                  onClick={() => setShowImageModal(true)}
                />
              </div>
            )}

            <div className='d-flex gap-2 align-items-center mb-3'>
              <input
                className='d-none'
                type='file'
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <label
                className={`cursor-pointer ${
                  isUploadMediaDisabled() ? 'theme-text-grey-2' : 'theme-text-green-1'
                }`}
                {...(!isUploadMediaDisabled() && { onClick: () => handleUploadClick() })}
              >
                {t('form_form_instructions_upload_media')}
              </label>

              <label className='theme-text-grey-2'>|</label>
              <span>{t('form_form_instructions_media_upload_info')}</span>
            </div>

            <div className='d-flex align-items-center'>
              <label
                className='theme-text-grey-2 cursor-pointer'
                onClick={() => {
                  handleResetInstructions();
                  setOldImageDeleted(false);
                  setOldPdfDeleted(false);
                }}
              >
                {t('text_reset')}
              </label>
              <div className='ms-auto d-flex gap-3'>
                {instructionData.oldExist && (
                  <FormLoadingButton
                    text={t('button_remove')}
                    variant='red-1'
                    minWidth
                    onClick={handleRemove}
                    loading={loaderType === 'delete' && loading}
                  />
                )}
                <FormButton
                  text={t('button_cancel')}
                  variant='white-1'
                  minWidth
                  onClick={handleCancelInstructions}
                />
                <FormLoadingButton
                  text='button_save'
                  variant='green-1'
                  minWidth
                  onClick={handleSave}
                  loading={loaderType === 'save' && loading}
                />
              </div>
            </div>
          </div>
        </div>
      </AlertModal>

      {showImageModal && (
        <PreviewImageModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageFile={instructionData.imageFile}
          existingImgUrl={existingImgUrl}
          handleUnAttachImg={handleUnAttachImg}
        />
      )}
      <AlertModal
        show={showSuccessAlert}
        showCloseButton={true}
        closeModal={() => setShowSuccessAlert(false)}
        title='alert_success'
        message={'alert_form_instruction_updated'}
        variant='success'
        actionButtons={[
          { text: t('button_ok'), variant: 'green-1', onClick: () => setShowSuccessAlert(false) },
        ]}
      />
    </>
  );
};

InstructionModal.propTypes = {
  showInstructionModal: PropTypes.bool,
  setShowInstructionModal: PropTypes.func,
  instructionData: PropTypes.object.isRequired,
  existingImgUrl: PropTypes.string,
  handleInstructionChange: PropTypes.func.isRequired,
  handleResetInstructions: PropTypes.func.isRequired,
  refreshFormInstructions: PropTypes.func.isRequired,
  handleCancelInstructions: PropTypes.func.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};

export default InstructionModal;
